import React, { useState, useEffect } from 'react';
import { Box, TextField, Typography, Button, Modal, Backdrop, Fade, IconButton, InputAdornment, Snackbar, Alert } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { auth } from '../firebase';
import { reauthenticateWithCredential, EmailAuthProvider, updatePassword, updateEmail } from 'firebase/auth';
import { getDatabase, ref, get, update } from 'firebase/database';
import PlanSelectionModal from './PlanSelectionModal';
import SurveyModal from './SurveyModal';
import RegistroCitaModal from './RegistroCitaModal';

const Perfil = () => {
  const [email, setEmail] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [nombres, setNombres] = useState('');
  const [apellidos, setApellidos] = useState('');
  const [nombreRegistro, setNombreRegistro] = useState('');
  const [numPersonas, setNumPersonas] = useState('');
  const [numPrimario, setNumPrimario] = useState('');
  const [otrosNumeros, setOtrosNumeros] = useState([]);
  const [planSeleccionado, setPlanSeleccionado] = useState('');
  const [isPlanModalOpen, setIsPlanModalOpen] = useState(false);
  const [isSurveyOpen, setIsSurveyOpen] = useState(false);
  const [surveyCompleted, setSurveyCompleted] = useState(false);
  const [registroExistente, setRegistroExistente] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isChangeEmailOpen, setIsChangeEmailOpen] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [passwordForEmailChange, setPasswordForEmailChange] = useState('');
  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [isChangeWhatsappOpen, setIsChangeWhatsappOpen] = useState(false);
  const [newWhatsapp, setNewWhatsapp] = useState('');
  const [passwordForWhatsappChange, setPasswordForWhatsappChange] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [isRegistroCitaModalOpen, setIsRegistroCitaModalOpen] = useState(false);

  useEffect(() => {
    if (auth.currentUser) {
      const db = getDatabase();
      const userRef = ref(db, `users/${auth.currentUser.uid}`);
      setEmail(auth.currentUser.email);

      get(userRef).then((snapshot) => {
        if (snapshot.exists()) {
          const userData = snapshot.val();
          setNombres(userData.nombres || '');
          setApellidos(userData.apellidos || '');
          setWhatsapp(userData.whatsapp || '');
          setNombreRegistro(userData.nombreRegistro || '');
          setNumPersonas(userData.numPersonas || '');
          setNumPrimario(userData.numPrimario || '');
          setOtrosNumeros(userData.otrosNumeros || []);
          setPlanSeleccionado(userData.plan || '');
          setSurveyCompleted(userData.surveyCompleted || false);

          // Abrir encuesta si no ha sido completada
          if (!userData.surveyCompleted) {
            setIsSurveyOpen(true);
          }

          // Determinar si mostrar la pregunta de registro
          if (!userData.nombreRegistro && !userData.numPersonas && !userData.numPrimario) {
            setRegistroExistente(null); // Mostrar la pregunta sobre registro existente
          }
        } else {
          setIsPlanModalOpen(true);
        }
      });
    }
  }, []);

  const handlePlanSelection = (plan) => {
    if (auth.currentUser) {
      const db = getDatabase();
      const userRef = ref(db, `users/${auth.currentUser.uid}`);
      update(userRef, { plan })
        .then(() => {
          setPlanSeleccionado(plan);
          setIsPlanModalOpen(false);
          if (!surveyCompleted) {
            setIsSurveyOpen(true);
          }
        })
        .catch((error) => {
          console.error('Error al actualizar el plan:', error);
        });
    }
  };

  const handleGuardarPerfil = () => {
    if (auth.currentUser) {
      const db = getDatabase();
      const userRef = ref(db, `users/${auth.currentUser.uid}`);
      const perfilData = {
        email,
        whatsapp,
        nombres,
        apellidos,
        nombreRegistro,
        numPersonas,
        numPrimario,
        otrosNumeros,
        plan: planSeleccionado,
        surveyCompleted,
      };

      update(userRef, perfilData)
        .then(() => {
          setShowSnackbar(true);
        })
        .catch((error) => {
          console.error('Error al guardar datos:', error);
        });
    }
  };

  const handleSurveyCompletion = () => {
    if (auth.currentUser) {
      const db = getDatabase();
      const userRef = ref(db, `users/${auth.currentUser.uid}`);
      update(userRef, { surveyCompleted: true })
        .then(() => {
          setSurveyCompleted(true);
          setIsSurveyOpen(false);
        })
        .catch((error) => {
          console.error('Error al actualizar el estado de la encuesta:', error);
        });
    }
  };

  const handleRegistroExistente = (respuesta) => {
    if (respuesta === 'si') {
      setRegistroExistente(true);
    } else {
      setRegistroExistente(false);
      setIsRegistroCitaModalOpen(true); // Abrir modal de cita si selecciona "No"
    }
  };

  const handleNumeroChange = (index, value) => {
    const nuevosNumeros = [...otrosNumeros];
    nuevosNumeros[index] = value;
    setOtrosNumeros(nuevosNumeros);
  };

  const handleChangeEmail = async () => {
    if (passwordForEmailChange && newEmail) {
      const credential = EmailAuthProvider.credential(auth.currentUser.email, passwordForEmailChange);
      try {
        await reauthenticateWithCredential(auth.currentUser, credential);
        await updateEmail(auth.currentUser, newEmail);
        const db = getDatabase();
        const userRef = ref(db, `users/${auth.currentUser.uid}`);
        update(userRef, { email: newEmail });
        setEmail(newEmail);
        setIsChangeEmailOpen(false);
        setPasswordForEmailChange('');
        setNewEmail('');
        alert('Correo electrónico actualizado correctamente');
      } catch (error) {
        console.error('Error al cambiar el correo:', error);
        alert('Error al cambiar el correo, verifica tu contraseña.');
      }
    } else {
      alert('Por favor ingresa todos los campos.');
    }
  };

  const handleChangeWhatsapp = async () => {
    if (passwordForWhatsappChange && newWhatsapp) {
      const credential = EmailAuthProvider.credential(auth.currentUser.email, passwordForWhatsappChange);
      try {
        await reauthenticateWithCredential(auth.currentUser, credential);
        const db = getDatabase();
        const userRef = ref(db, `users/${auth.currentUser.uid}`);
        update(userRef, { whatsapp: newWhatsapp });
        setWhatsapp(newWhatsapp);
        setIsChangeWhatsappOpen(false);
        setPasswordForWhatsappChange('');
        setNewWhatsapp('');
        alert('Número de WhatsApp actualizado correctamente');
      } catch (error) {
        console.error('Error al cambiar el WhatsApp:', error);
        alert('Error al cambiar el WhatsApp, verifica tu contraseña.');
      }
    } else {
      alert('Por favor ingresa todos los campos.');
    }
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmNewPassword) {
      alert('Las contraseñas no coinciden.');
      return;
    }
    if (oldPassword && newPassword) {
      const credential = EmailAuthProvider.credential(auth.currentUser.email, oldPassword);
      try {
        await reauthenticateWithCredential(auth.currentUser, credential);
        await updatePassword(auth.currentUser, newPassword);
        setIsChangePasswordOpen(false);
        setOldPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
        alert('Clave actualizada correctamente');
      } catch (error) {
        console.error('Error al cambiar la clave:', error);
        alert('Error al cambiar la clave, verifica tu contraseña.');
      }
    } else {
      alert('Por favor ingresa todos los campos.');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        mt: 4,
        p: 2,
        border: '1px solid #ccc',
        borderRadius: 2,
        bgcolor: '#f5f5f5',
        maxWidth: 500,
        mx: 'auto',
      }}
    >
      <Typography variant="h5" sx={{ mb: 2, color: '#333' }}>
        Información del Perfil
      </Typography>

      {/* Mostrar el plan actual */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
        <TextField
          label="Plan Actual"
          variant="outlined"
          fullWidth
          value={planSeleccionado || 'Sin plan'}
          disabled
        />
        <Button variant="outlined" onClick={() => setIsPlanModalOpen(true)} sx={buttonStyles}>
          Cambiar
        </Button>
      </Box>

      {/* Campo de correo electrónico bloqueado */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
        <TextField label="Correo Electrónico" variant="outlined" fullWidth value={email} disabled />
        <Button variant="outlined" onClick={() => setIsChangeEmailOpen(true)} sx={buttonStyles}>
          Cambiar
        </Button>
      </Box>

      {/* Campo de WhatsApp */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
        <PhoneInput
          country={'mx'}
          value={whatsapp}
          onChange={setWhatsapp}
          placeholder="WhatsApp Ej. +52 1234567890"
          inputStyle={{
            width: '100%',
            height: '56px',
            fontSize: '16px',
            borderRadius: '4px',
            border: '1px solid #ccc',
            paddingLeft: '48px',
            backgroundColor: '#f5f5f5',
          }}
          buttonStyle={{
            border: 'none',
            backgroundColor: 'transparent',
          }}
          containerStyle={{ width: '100%' }}
          enableAreaCodes={true}
          disableCountryCode={false}
          disableDropdown={false}
          countryCodeEditable={true}
          disabled={whatsapp !== ''}
        />
        <Button variant="outlined" onClick={() => setIsChangeWhatsappOpen(true)} sx={buttonStyles}>
          Cambiar
        </Button>
      </Box>

      {/* Otros campos de perfil */}
      <TextField
        label="Nombres"
        variant="outlined"
        fullWidth
        value={nombres}
        onChange={(e) => setNombres(e.target.value)}
        disabled={nombres !== ''}
      />
      <TextField
        label="Apellidos"
        variant="outlined"
        fullWidth
        value={apellidos}
        onChange={(e) => setApellidos(e.target.value)}
        disabled={apellidos !== ''}
      />

      {/* Pregunta sobre registro en CBPONE */}
      {!nombreRegistro && !numPersonas && !numPrimario && (
        <>
          <Typography variant="body1" sx={{ mt: 2, color: '#333', textAlign: 'center' }}>
            ¿Ya tiene un registro hecho en CBPONE?
          </Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button variant="contained" onClick={() => handleRegistroExistente('si')} sx={buttonStyles}>
              Sí
            </Button>
            <Button variant="contained" onClick={() => handleRegistroExistente('no')} sx={buttonStyles}>
              No
            </Button>
          </Box>
        </>
      )}

      {/* Mostrar campos adicionales si selecciona 'Sí' */}
      {registroExistente && (
        <>
          <TextField
            label="Nombre Principal de Registro"
            variant="outlined"
            fullWidth
            value={nombreRegistro}
            onChange={(e) => setNombreRegistro(e.target.value)}
          />
          <TextField
            label="Número de Personas en el Registro (máximo 10)"
            variant="outlined"
            fullWidth
            type="number"
            value={numPersonas}
            onChange={(e) => setNumPersonas(parseInt(e.target.value, 10))}
            InputProps={{ inputProps: { min: 1, max: 10 } }}
          />
          <TextField
            label="Número Primario del Registro (8 dígitos)"
            variant="outlined"
            fullWidth
            value={numPrimario}
            onChange={(e) => {
              const val = e.target.value;
              if (val.length <= 8) setNumPrimario(val);
            }}
            inputProps={{ pattern: '[0-9]{8}' }}
          />

          {otrosNumeros.map((numero, index) => (
            <TextField
              key={index}
              label={`Otro Número ${index + 1}`}
              variant="outlined"
              fullWidth
              value={numero}
              onChange={(e) => handleNumeroChange(index, e.target.value)}
              sx={{ mb: 1 }}
            />
          ))}
        </>
      )}

      {/* Botón de Guardar Datos */}
      <Button variant="contained" onClick={handleGuardarPerfil} sx={{ ...buttonStyles, mt: 2 }}>
        Guardar Datos
      </Button>

      {/* Botón de Cambiar Clave */}
      <Button variant="outlined" onClick={() => setIsChangePasswordOpen(true)} sx={buttonStyles}>
        Cambiar Clave
      </Button>

      {/* Modal de asistencia personalizada */}
      <RegistroCitaModal open={isRegistroCitaModalOpen} onClose={() => setIsRegistroCitaModalOpen(false)} />

      {/* Snackbar de éxito */}
      <Snackbar open={showSnackbar} autoHideDuration={3000} onClose={() => setShowSnackbar(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
        <Alert onClose={() => setShowSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          Datos guardados correctamente
        </Alert>
      </Snackbar>

      {/* Modal de selección de plan */}
      <PlanSelectionModal open={isPlanModalOpen} onClose={() => setIsPlanModalOpen(false)} handlePlanSelection={handlePlanSelection} />

      {/* Modal de encuesta */}
      <SurveyModal open={isSurveyOpen} onClose={handleSurveyCompletion} />

      {/* Modales de cambio de correo, WhatsApp y contraseña */}
      {/* ... Aquí se mantiene el código de los demás modales para cambio de información ... */}
    </Box>
  );
};

// Estilos para los botones y modales
const buttonStyles = {
  mt: 1,
  borderColor: '#fff',
  color: '#000',
  bgcolor: '#fff',
  fontWeight: 'bold',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  '&:hover': {
    bgcolor: '#000',
    borderColor: '#fff',
    color: '#fff',
  },
};

export default Perfil;
