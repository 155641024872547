import React, { useState, useEffect } from 'react';
import { Box, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, IconButton } from '@mui/material';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import { getDatabase, ref, get } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import TerminalSimulator from '../components/TerminalSimulator';
import Perfil from '../components/Perfil';
import Estrategias from '../components/Estrategias';
import Noticias from '../components/Noticias';
import Blogs from '../components/Blogs';
import Puerto from '../components/Puerto';
import Paralegal from '../components/Paralegal';
import FAQ from '../components/FAQ';
import { Person, Terminal, Newspaper, Article, Help, Map, Gavel, AccountBalance, PowerSettingsNew } from '@mui/icons-material';

const ProfilePage = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [activeTab, setActiveTab] = useState('profile');
  const [userPlan, setUserPlan] = useState(''); // Estado para el plan del usuario
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.currentUser) {
      setUserEmail(auth.currentUser.email);
      // Obtener el plan del usuario desde Firebase
      const fetchUserPlan = async () => {
        const db = getDatabase();
        const userRef = ref(db, `users/${auth.currentUser.uid}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const userData = snapshot.val();
          setUserPlan(userData.plan || 'Básico'); // Por defecto, el plan será "Básico" si no se especifica
        }
      };
      fetchUserPlan();
    }

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      localStorage.clear();
      sessionStorage.clear();
      navigate('/');
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
    }
  };

  // Determinar si el plan es en modo demo o si está pagado
  const isDemo = userPlan === 'Básico' || userPlan.includes('(Esperando Pago)');
  const isPaidLiteOrPlus = userPlan === 'Lite (Pago)' || userPlan === 'Plus (Pago)';

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: { xs: 2, md: 4 }, px: { xs: 2, sm: 4 }, width: '100%', maxWidth: '1200px', mx: 'auto', boxSizing: 'border-box' }}>
      {/* Mensaje de Bienvenida */}
      <Typography variant="h5" sx={{ mb: { xs: 1, md: 2 }, fontSize: { xs: '18px', sm: '20px', md: '24px' }, color: '#000', textAlign: 'center' }}>
        Bienvenido, <strong>user: {userEmail}</strong>
      </Typography>

      {/* Sub-Navbar */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          gap: { xs: 1, sm: 2, md: 3 },
          mb: 3,
          p: 1,
          px: 2,
          bgcolor: '#222',
          borderRadius: 2,
          boxShadow: '0 4px 8px rgba(0,0,0,0.3)',
          width: 'calc(100% - 16px)', // Espacio pequeño desde el borde
          maxWidth: '1200px',
          transition: 'all 0.3s',
          flexWrap: { xs: 'wrap', sm: 'nowrap' }, // Permitir dividir en dos filas si es necesario en pantallas pequeñas
          justifyContent: 'center',
        }}
      >
        {[
          { label: 'Perfil', icon: <Person fontSize="inherit" />, tab: 'profile', disabled: false },
          { label: 'Terminal Estadística', icon: <Terminal fontSize="inherit" />, tab: 'terminal', disabled: false },
          { label: 'Noticias', icon: <Newspaper fontSize="inherit" />, tab: 'news', disabled: false },
          { label: 'Blogs', icon: <Article fontSize="inherit" />, tab: 'blogs', disabled: false },
          { label: 'Estrategias', icon: <Map fontSize="inherit" />, tab: 'strategies', disabled: !isPaidLiteOrPlus },
          { label: 'Puerto', icon: <AccountBalance fontSize="inherit" />, tab: 'port', disabled: false },
          { label: 'Paralegal', icon: <Gavel fontSize="inherit" />, tab: 'paralegal', disabled: false },
          { label: 'FAQ', icon: <Help fontSize="inherit" />, tab: 'faq', disabled: false },
          { label: 'Cerrar Sesión', icon: <PowerSettingsNew fontSize="inherit" />, action: handleOpenDialog, disabled: false },
        ].map((item) => (
          <Box
            key={item.label}
            onClick={() => item.action ? item.action() : setActiveTab(item.tab)}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              flex: '1 1 auto',
              minWidth: '0',
              cursor: 'pointer',
              transition: 'background-color 0.3s',
              opacity: item.disabled ? 0.5 : 1,
              pointerEvents: item.disabled ? 'none' : 'auto',
              textAlign: 'center',
              px: 1,
              '&:hover': {
                bgcolor: !item.disabled ? '#ccc' : 'transparent', // Fondo gris si se pasa el cursor y no está deshabilitado
              },
              '&:hover .icon, &:hover .label': {
                color: !item.disabled ? '#000' : '#fff', // Cambiar el color del icono y el texto al pasar el cursor
              },
            }}
          >
            <IconButton
              className="icon"
              sx={{
                color: activeTab === item.tab && !item.disabled ? '#007bff' : '#fff', // Azul para el icono si está activo
                fontSize: { xs: '6vw', sm: '3.5vw', md: '2.5vw' }, // Iconos más grandes en pantallas pequeñas
                transition: 'color 0.3s',
              }}
            >
              {item.icon}
            </IconButton>
            <Typography
              variant="caption"
              className="label"
              sx={{
                color: activeTab === item.tab && !item.disabled ? '#007bff' : '#fff', // Azul para el texto si está activo
                fontSize: { xs: '3vw', sm: '1.5vw', md: '1.2vw' }, // Texto más grande en pantallas pequeñas
                textAlign: 'center',
                mt: 0.5,
                transition: 'color 0.3s',
              }}
            >
              {item.label}
            </Typography>
          </Box>
        ))}
      </Box>

      {/* Contenido según la pestaña activa */}
      <Box sx={{ width: '100%', maxWidth: '100%', px: { xs: 2, sm: 4, md: 6 }, py: { xs: 2, md: 4 }, flex: '1 1 auto', display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ width: '100%', maxWidth: '1000px', boxSizing: 'border-box' }}>
          {activeTab === 'profile' && <Perfil />}
          {activeTab === 'terminal' && (
            <>
              {/* Mensaje de demostración si el plan es "Básico" o "esperando pago" */}
              {isDemo ? (
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    color: '#ff0',
                    backgroundColor: '#333',
                    padding: '10px',
                    borderRadius: '5px',
                    mb: 2,
                  }}
                >
                  Esta es una demostración. No se estará realizando ninguna tarea real ya que su plan es Básico o está "Esperando Pago".
                </Typography>
              ) : (
                <TerminalSimulator isDemo={false} />
              )}
              {isDemo && <TerminalSimulator isDemo={true} />}
            </>
          )}
          {activeTab === 'news' && <Noticias />}
          {activeTab === 'blogs' && <Blogs />}
          {activeTab === 'strategies' && <Estrategias />}
          {activeTab === 'port' && <Puerto />}
          {activeTab === 'paralegal' && <Paralegal />}
          {activeTab === 'faq' && <FAQ />}
        </Box>
      </Box>

      {/* Diálogo de confirmación de cierre de sesión */}
      <Dialog open={openDialog} onClose={handleCloseDialog} PaperProps={{ sx: { bgcolor: 'rgba(0, 0, 0, 0.7)', backdropFilter: 'blur(15px)', borderRadius: 2, border: '1px solid rgba(255, 255, 255, 0.2)', boxShadow: '0 8px 32px rgba(0, 0, 0, 0.7)', color: '#fff', p: 2 } }}>
        <DialogTitle sx={{ fontWeight: 'bold' }}>Cerrar Sesión</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: '#bbb' }}>¿Estás seguro de que deseas cerrar la sesión?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} sx={{ color: '#fff', bgcolor: '#555', '&:hover': { bgcolor: '#666' } }}>
            Cancelar
          </Button>
          <Button onClick={handleLogout} sx={{ bgcolor: '#d32f2f', color: '#fff', '&:hover': { bgcolor: '#b71c1c' } }}>
            Cerrar Sesión
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProfilePage;
