import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';

// Obtener el contenedor 'root' del archivo HTML
const rootElement = document.getElementById('root');

// Crear el root de React 18
const root = ReactDOM.createRoot(rootElement);

// Renderizar la aplicación sin el tema de Material UI
root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);
