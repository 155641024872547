import React, { useEffect, useState } from 'react';
import { Fab } from '@mui/material';  // Botón flotante de Material UI
import WhatsAppIcon from '@mui/icons-material/WhatsApp';  // Ícono de WhatsApp
import { keyframes } from '@mui/system';  // Para definir la animación con keyframes

// Definir la animación de flotación
const floatAnimation = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
`;

const WhatsAppButton = () => {
  return (
    <Fab
      href="https://wa.me/527202271108"  // Reemplaza con tu número de WhatsApp
      target="_blank"
      rel="noopener noreferrer"
      color="success"
      sx={{
        position: 'fixed',
        bottom: 20,
        right: 20,
        zIndex: 1000,
        backgroundColor: '#25D366',
        color: '#fff',
        animation: `${floatAnimation} 3s ease-in-out infinite`,  // Aplicar la animación
      }}
    >
      <WhatsAppIcon sx={{ fontSize: 40 }} />
    </Fab>
  );
};

export default WhatsAppButton;
