import React, { useState } from 'react';
import { Box, Button, Modal, TextField, Typography, Backdrop, Fade, Divider, IconButton, InputAdornment, Alert } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Login as LoginIcon } from '@mui/icons-material'; // Ícono de login del navbar
import FacebookIcon from '@mui/icons-material/Facebook';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth';
import { auth } from '../firebase';

const LoginModal = ({ open, handleClose, openRegisterModal }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [authError, setAuthError] = useState(null);
  const navigate = useNavigate(); // Inicializar useNavigate

  // Validar el formato del correo
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  // Validar la longitud mínima de la contraseña
  const validatePassword = (password) => password.length >= 6;

  // Manejar la autenticación con correo y contraseña
  const handleLogin = async (e) => {
    e.preventDefault();
    setAuthError(null);

    if (!validateEmail(email)) {
      setEmailError(true);
      return;
    }
    if (!validatePassword(password)) {
      setPasswordError(true);
      return;
    }

    try {
      await signInWithEmailAndPassword(auth, email, password);
      setEmail('');
      setPassword('');
      handleClose();
      navigate('/profile'); // Redirigir a ProfilePage.js
    } catch (error) {
      setAuthError('Correo o contraseña incorrectos');
    }
  };

  // Manejar la autenticación con Google
  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      setEmail('');
      setPassword('');
      handleClose();
      navigate('/profile'); // Redirigir a ProfilePage.js
    } catch (error) {
      setAuthError('Error al iniciar sesión con Google');
    }
  };

  // Manejar la autenticación con Facebook
  const handleFacebookLogin = async () => {
    const provider = new FacebookAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      setEmail('');
      setPassword('');
      handleClose();
      navigate('/profile'); // Redirigir a ProfilePage.js
    } catch (error) {
      setAuthError('Error al iniciar sesión con Facebook');
    }
  };

  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);

  return (
    <Modal
      open={open}
      onClose={() => {
        setEmail('');
        setPassword('');
        setAuthError(null);
        handleClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 350,
            maxHeight: '90vh',
            overflowY: 'auto',
            bgcolor: 'rgba(0, 0, 0, 0.7)',
            borderRadius: 2,
            border: '1px solid rgba(255, 255, 255, 0.2)',
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.7)',
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: '#fff',
          }}
        >
          <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
            Iniciar Sesión
          </Typography>

          {authError && (
            <Alert severity="error" sx={{ mb: 2, bgcolor: '#333', color: '#fff' }}>
              {authError}
            </Alert>
          )}

          <form onSubmit={handleLogin} style={{ width: '100%' }}>
            <TextField
              fullWidth
              variant="outlined"
              label="Email"
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                if (emailError) setEmailError(false);
              }}
              onBlur={() => setEmailError(!validateEmail(email))}
              error={emailError}
              helperText={emailError ? 'Formato de correo inválido' : ''}
              sx={{ mb: 2, bgcolor: '#444', borderRadius: 1, input: { color: '#fff' }, label: { color: '#bbb' } }}
            />
            <TextField
              fullWidth
              variant="outlined"
              label="Contraseña"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                if (passwordError) setPasswordError(false);
              }}
              onBlur={() => setPasswordError(!validatePassword(password))}
              error={passwordError}
              helperText={passwordError ? 'La contraseña debe tener al menos 6 caracteres' : ''}
              sx={{ mb: 2, bgcolor: '#444', borderRadius: 1, input: { color: '#fff' }, label: { color: '#bbb' } }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={togglePasswordVisibility}
                      edge="end"
                      sx={{ color: '#fff' }}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              variant="outlined"
              fullWidth
              sx={{
                mt: 1,
                borderColor: '#fff',
                color: '#000',
                bgcolor: '#fff',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                '&:hover': {
                  bgcolor: '#000',
                  borderColor: '#fff',
                  color: '#fff',
                },
              }}
            >
              <LoginIcon />
              Iniciar Sesión
            </Button>
          </form>

          <Divider sx={{ width: '100%', my: 2, bgcolor: '#bbb' }}>O</Divider>

          <Button
            variant="contained"
            fullWidth
            onClick={handleGoogleLogin}
            sx={{
              mb: 1,
              bgcolor: '#fff',
              color: '#4285F4',
              textTransform: 'none',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
              '&:hover': { bgcolor: '#f5f5f5' },
            }}
          >
            <img src={`${process.env.PUBLIC_URL}/Logo-google-icon.png`} alt="Google Icon" style={{ width: 24, height: 24 }} />
            Iniciar con Google
          </Button>

          <Button
            variant="contained"
            fullWidth
            onClick={handleFacebookLogin}
            sx={{
              mb: 1,
              bgcolor: '#1877F2',
              color: '#fff',
              textTransform: 'none',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
              '&:hover': { bgcolor: '#1558b0' },
            }}
          >
            <FacebookIcon />
            Iniciar con <strong>Facebook</strong>
          </Button>

          <Typography
            variant="body2"
            sx={{ mt: 2, color: '#bbb' }}
          >
            ¿No tienes cuenta?{' '}
            <span
              style={{ color: '#fff', textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => {
                handleClose();
                openRegisterModal();
              }}
            >
              Regístrate
            </span>
          </Typography>
        </Box>
      </Fade>
    </Modal>
  );
};

export default LoginModal;
