import React, { useEffect, useState } from 'react';
import { Box, Container, Typography, IconButton } from '@mui/material';
import { Facebook, Twitter, LinkedIn, Instagram } from '@mui/icons-material';
import zIndex from '@mui/material/styles/zIndex';

const Footer = () => {
  const [visible, setVisible] = useState(false);

  // Efecto de aparición lenta al cargar el Footer
  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, 300);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Box
      component="footer"
      sx={{
        position: 'sticky',
        bottom: 0,
        py: 2,
        backgroundColor: 'rgba(0, 0, 0, 0.80)',  // Fondo oscuro translúcido
        backdropFilter: 'blur(10px)',  // Efecto de blur
        color: '#fff',
        textAlign: 'center',
        opacity: visible ? 1 : 0,  // Opacidad 0.8 al cargar
        transition: 'opacity 1.5s ease-in',
        zIndex:3,
      }}

    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
        }}
      >
        {/* Texto del Footer */}
        <Typography variant="body1">
          © {new Date().getFullYear()} CBPone Consulting. Todos los derechos reservados.
        </Typography>

        {/* Enlaces de redes sociales */}
        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
          <IconButton href="https://facebook.com" target="_blank" color="inherit">
            <Facebook />
          </IconButton>
          <IconButton href="https://twitter.com" target="_blank" color="inherit">
            <Twitter />
          </IconButton>
          <IconButton href="https://linkedin.com" target="_blank" color="inherit">
            <LinkedIn />
          </IconButton>
          <IconButton href="https://instagram.com" target="_blank" color="inherit">
            <Instagram />
          </IconButton>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
