import React from 'react';
import { Box, Typography } from '@mui/material';

const Blogs = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
      <Typography variant="h4" sx={{ mb: 2, color: '#000' }}>
        Blogs
      </Typography>
      <Typography variant="body1" sx={{ color: '#555' }}>
        Aquí se mostrarán publicaciones de blogs relevantes.
      </Typography>
    </Box>
  );
};

export default Blogs;
