import React, { useRef, useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Tooltip, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import { Icon } from 'leaflet';

// Datos de los puertos
// Datos de los puertos (PuertoMap.js)
const portsData = [
  {
    name: 'Nogales',
    lat: 31.3356,
    lon: -110.9457,
    mexicanAirport: 'HMO - Aeropuerto Internacional de Hermosillo General Ignacio Pesqueira',
    usAirport: 'TUS - Tucson International Airport',
    address: 'Nogales, Sonora, México',
  },
  {
    name: 'Brownsville',
    lat: 25.9017,
    lon: -97.4975,
    mexicanAirport: 'MAM - Aeropuerto Internacional General Servando Canales de Matamoros',
    usAirport: 'BRO - Brownsville/South Padre Island International Airport',
    address: 'Matamoros, Tamaulipas, México',
  },
  {
    name: 'Eagle Pass',
    lat: 28.7091,
    lon: -100.4995,
    mexicanAirport: 'PDS - Aeropuerto Internacional de Piedras Negras',
    usAirport: 'SAT - San Antonio International Airport',
    address: 'Piedras Negras, Coahuila, México',
  },
  {
    name: 'Hidalgo',
    lat: 26.1045,
    lon: -98.2624,
    mexicanAirport: 'REX - Aeropuerto Internacional General Lucio Blanco',
    usAirport: 'MFE - McAllen Miller International Airport',
    address: 'Reynosa, Tamaulipas, México',
  },
  {
    name: 'Laredo',
    lat: 27.5064,
    lon: -99.5075,
    mexicanAirport: 'NLD - Aeropuerto Internacional Quetzalcóatl',
    usAirport: 'LRD - Laredo International Airport',
    address: 'Nuevo Laredo, Tamaulipas, México',
  },
  {
    name: 'Paso del Norte',
    lat: 31.7587,
    lon: -106.4869,
    mexicanAirport: 'CJS - Aeropuerto Internacional Abraham González',
    usAirport: 'ELP - El Paso International Airport',
    address: 'Ciudad Juárez, Chihuahua, México',
  },
  {
    name: 'Calexico',
    lat: 32.6789,
    lon: -115.4989,
    mexicanAirport: 'MXL - Aeropuerto Internacional General Rodolfo Sánchez Taboada',
    usAirport: 'CXL - Calexico International Airport',
    address: 'Mexicali, Baja California, México',
  },
  {
    name: 'San Ysidro',
    lat: 32.5433,
    lon: -117.0296,
    mexicanAirport: 'TIJ - Aeropuerto Internacional General Abelardo L. Rodríguez',
    usAirport: 'SAN - San Diego International Airport',
    address: 'Tijuana, Baja California, México',
  },
];


const PuertoMap = () => {
  const popupRefs = useRef([]);
  const [manualInteraction, setManualInteraction] = useState(false);

  useEffect(() => {
    let currentIndex = 0;
    let isManual = false;

    const interval = setInterval(() => {
      if (isManual) return;

      // Cerrar todos los popups antes de abrir uno nuevo
      popupRefs.current.forEach((popup) => {
        if (popup && popup._source) {
          popup._source.closePopup();
        }
      });

      // Esperar 3 segundos antes de abrir el siguiente popup
      setTimeout(() => {
        if (!isManual && popupRefs.current[currentIndex] && popupRefs.current[currentIndex]._source) {
          popupRefs.current[currentIndex]._source.openPopup();
        }
      }, 3000); // Espera de 3 segundos antes de abrir el siguiente popup

      currentIndex = (currentIndex + 1) % portsData.length;
    }, 6000); // Tiempo total del ciclo de animación: 3 segundos cerrado + 3 segundos abierto

    return () => clearInterval(interval);
  }, [manualInteraction]);

  const handleMarkerClick = (index) => {
    // Detener la animación automática cuando el usuario hace clic en un marcador
    setManualInteraction(true);

    // Cerrar todos los popups antes de abrir el seleccionado
    popupRefs.current.forEach((popup) => {
      if (popup && popup._source) {
        popup._source.closePopup();
      }
    });

    // Abrir el popup correspondiente al marcador seleccionado
    if (popupRefs.current[index] && popupRefs.current[index]._source) {
      popupRefs.current[index]._source.openPopup();
    }
  };

  return (
    <div
      style={{
        width: '100%',
        height: '500px',
        overflow: 'hidden',
        marginBottom: '20px',
        position: 'relative',
        zIndex: 1, // Coloca el mapa detrás de otros elementos
      }}
    >
      <MapContainer
        center={[27.6446, -106.4355]} // Centrar el mapa en las coordenadas proporcionadas
        zoom={5}
        style={{ width: '100%', height: '100%', minHeight: '300px', borderRadius: '10px' }}
        dragging={false} // Deshabilitar el arrastre del mapa
        doubleClickZoom={false} // Deshabilitar el zoom con doble clic
        zoomControl={false} // Ocultar el control de zoom
        scrollWheelZoom={false} // Deshabilitar el zoom con la rueda del mouse
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {portsData.map((port, index) => (
          <Marker
            key={index}
            position={[port.lat, port.lon]}
            icon={
              new Icon({
                iconUrl: markerIconPng,
                iconSize: [30, 45], // Tamaño adecuado para la visibilidad
                iconAnchor: [15, 45], // Alinear correctamente el ícono
              })
            }
            eventHandlers={{
              click: () => handleMarkerClick(index),
            }}
          >
            <Tooltip
              direction={
                port.name === 'Calexico' ? 'right' :
                port.name === 'Brownsville' ? 'right' :
                port.name === 'Nogales' ? 'bottom' : // Cambiar el tooltip de Nogales a la parte inferior
                'top'
              }
              offset={[0, -30]}
              permanent
            >
              <span style={{ fontSize: '14px', fontWeight: 'bold', color: '#000' }}>{port.name}</span>
            </Tooltip>
            <Popup ref={(el) => (popupRefs.current[index] = el)}>
              <b>{port.name}</b>
              <br />
              <div><b>Aeropuerto Mexicano:</b> {port.mexicanAirport}</div>
              <div><b>Aeropuerto Americano:</b> {port.usAirport}</div>
              <div>
                <b>Dirección:</b>{' '}
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(port.address)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {port.address}
                </a>
              </div>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default PuertoMap;
