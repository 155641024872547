import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, IconButton, Drawer, List, ListItem, ListItemText, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginModal from './LoginModal';
import RegisterModal from './RegisterModal';
import ContactModal from './ContactModal';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';

const Navbar = () => {
  const [visible, setVisible] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [registerModalOpen, setRegisterModalOpen] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [logoutConfirmOpen, setLogoutConfirmOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, 300);

    // Verificar si hay un usuario autenticado
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsLoggedIn(!!user);
    });

    return () => {
      clearTimeout(timer);
      unsubscribe();
    };
  }, []);

  // Funciones para abrir y cerrar modales
  const handleLoginOpen = () => setLoginModalOpen(true);
  const handleLoginClose = () => setLoginModalOpen(false);
  const handleRegisterOpen = () => setRegisterModalOpen(true);
  const handleRegisterClose = () => setRegisterModalOpen(false);
  const handleContactOpen = () => setContactModalOpen(true);
  const handleContactClose = () => setContactModalOpen(false);

  // Función para cerrar sesión
  const handleLogout = async () => {
    try {
      await signOut(auth);
      setIsLoggedIn(false);
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
    }
  };

  // Abrir el modal de confirmación de cierre de sesión
  const openLogoutConfirm = () => setLogoutConfirmOpen(true);

  // Cerrar el modal de confirmación de cierre de sesión
  const closeLogoutConfirm = () => setLogoutConfirmOpen(false);

  // Confirmar el cierre de sesión
  const confirmLogout = () => {
    handleLogout();
    closeLogoutConfirm();
  };

  // Menú principal
  const menuItems = [
    { label: 'Inicio', icon: <HomeIcon />, link: '/' },
    ...(location.pathname !== '/profile' && !isLoggedIn
      ? [
          { label: 'Login', icon: <LoginIcon />, onClick: handleLoginOpen },
          { label: 'Register', icon: <PersonAddIcon />, onClick: handleRegisterOpen },
        ]
      : []),
    { label: 'Contáctanos', icon: <ContactMailIcon />, onClick: handleContactOpen },
    ...(isLoggedIn
      ? [{ label: 'Cerrar Sesión', icon: <LogoutIcon />, onClick: openLogoutConfirm }]
      : []),
  ];

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.80)',
          backdropFilter: 'blur(10px)',
          opacity: visible ? 1 : 0,
          transition: 'opacity 1.5s ease-in',
          boxShadow: 'none',
          
        }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* Logo del Navbar */}
          <Box display="flex" alignItems="center">
            <Link to="/" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
              <img
                src={`${process.env.PUBLIC_URL}/logo.png`}
                alt="Logo"
                style={{ height: '55px', marginBottom: '-5px', marginRight: '8px' }}
              />
              <Typography variant="h6" component="span" sx={{ color: '#fff' }}>
                CBPone Consulting
              </Typography>
            </Link>
          </Box>

          {/* Menú en escritorio */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 4, alignItems: 'center' }}>
            {menuItems.map((item, index) => (
              <Button
                key={index}
                component={item.link ? Link : 'button'}
                to={item.link}
                onClick={item.onClick}
                color="inherit"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  fontWeight: '600',
                  color: '#fff',
                  textTransform: 'none',
                  transition: '0.3s',
                  '& .MuiTypography-root': {
                    color: '#fff',
                    transition: 'color 0.3s',
                  },
                }}
              >
                {item.icon}
                <Typography variant="caption" sx={{ mt: 0.5 }}>
                  {item.label}
                </Typography>
              </Button>
            ))}
          </Box>

          {/* Menú en dispositivos móviles */}
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{ display: { xs: 'flex', md: 'none' } }}
            onClick={() => setDrawerOpen(true)}
          >
            <MenuIcon />
          </IconButton>

          {/* Drawer para dispositivos móviles */}
          <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            sx={{
              '& .MuiDrawer-paper': {
                bgcolor: 'rgba(0, 0, 0, 0.80)',
                color: '#fff', // Texto blanco
              },
            }}
          >
            <List>
              {menuItems.map((item, index) => (
                <ListItem
                  key={index}
                  button
                  component={item.link ? Link : 'button'}
                  to={item.link}
                  onClick={() => {
                    setDrawerOpen(false);
                    if (item.onClick) item.onClick();
                  }}
                  sx={{
                    bgcolor:'rgba(0, 0, 0, 0.80)', // Fondo siempre negro
                    color: '#fff', // Texto siempre blanco
                    display: 'flex',
                    alignItems: 'center',
                    '& .MuiListItemText-root': {
                      color: '#fff', // Siempre texto blanco
                    },
                    '&:hover': {
                      bgcolor: '#000', // Fondo permanece negro incluso en hover
                      color: '#fff', // Texto permanece blanco incluso en hover
                    },
                  }}
                >
                  {item.icon}
                  <ListItemText primary={item.label} sx={{ ml: 1 }} />
                </ListItem>
              ))}
            </List>
          </Drawer>
        </Toolbar>
      </AppBar>

      {/* Modal de confirmación de cierre de sesión */}
      <Dialog
        open={logoutConfirmOpen}
        onClose={closeLogoutConfirm}
        PaperProps={{
          sx: {
            bgcolor: 'rgba(0, 0, 0, 0.8)',
            color: '#fff',
            borderRadius: 2,
            p: 2,
          },
        }}
      >
        <DialogTitle>Cerrar Sesión</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: '#bbb' }}>
            ¿Estás seguro de que deseas cerrar sesión?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeLogoutConfirm}
            sx={{ color: '#fff', bgcolor: '#d32f2f', '&:hover': { bgcolor: '#b71c1c' } }}
          >
            Cancelar
          </Button>
          <Button
            onClick={confirmLogout}
            sx={{ color: '#fff', bgcolor: '#1976d2', '&:hover': { bgcolor: '#1565c0' } }}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal de login */}
      <LoginModal open={loginModalOpen} handleClose={handleLoginClose} openRegisterModal={handleRegisterOpen} />

      {/* Modal de registro */}
      <RegisterModal open={registerModalOpen} handleClose={handleRegisterClose} openLoginModal={handleLoginOpen} />

      {/* Modal de contacto */}
      <ContactModal open={contactModalOpen} handleClose={handleContactClose} />
    </>
  );
};

export default Navbar;
