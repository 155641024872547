import React from 'react';
import { Modal, Box, Typography, Card, CardContent, CardActions, Button } from '@mui/material';

// Generar un código único de 12 caracteres
const generateUniqueCode = () => {
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*';
  let code = '';
  for (let i = 0; i < 12; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    code += charset[randomIndex];
  }
  return code;
};

// El componente PlanSelectionModal ahora acepta la prop handlePlanSelection
const PlanSelectionModal = ({ open, onClose, handlePlanSelection }) => {
  const handleSelection = (plan) => {
    // Verificamos si handlePlanSelection es una función antes de invocarla
    if (typeof handlePlanSelection === 'function') {
      // Generar un código único
      const uniqueCode = generateUniqueCode();

      // Modificar el valor a guardar según el plan seleccionado
      const planToSave = `${plan} (Esperando Pago)`;

      // Notificar al componente padre con el plan actualizado y el código único generado
      handlePlanSelection(planToSave, uniqueCode);
    } else {
      console.error('handlePlanSelection no es una función');
    }

    // Cerrar el modal
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyles}>
        <Typography variant="h6" sx={{ mb: 2 }}>Selecciona tu Plan</Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          "Somos una fundación sin fines de lucro, pero tampoco estamos destinados a operar con pérdidas. Los planes tienen un costo anual, más que suficiente para que complete su proceso de obtención de cita. Con ello, financiamos nuestros servicios. Como motivo de lanzamiento, el Plan Básico será gratuito."
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
          {['Básico', 'Lite', 'Plus'].map((plan) => (
            <Card key={plan} sx={{ minWidth: 200, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h6" sx={{ mb: 1 }}>{plan}</Typography>
                <ul>
                  {plan === 'Básico' && (
                    <>
                      <li>Acceso limitado.</li>
                    </>
                  )}
                  {plan === 'Lite' && (
                    <>
                      <li>Acceso a la Terminal Estadística.</li>
                      <li>Acceso a Estrategias.</li>
                    </>
                  )}
                  {plan === 'Plus' && (
                    <>
                      <li>Acceso a la Terminal Estadística.</li>
                      <li>Acceso a Estrategias.</li>
                      <li>Asistencia personalizada.</li>
                    </>
                  )}
                </ul>
                <Typography variant="body2" sx={{ mt: 2 }}>
                  {plan === 'Básico' && (
                    <><s>20 USD</s> - Hoy, gratis por lanzamiento en beta</>
                  )}
                  {plan === 'Lite' && (
                    <><s>50 USD</s> - Hoy, 20 USD por lanzamiento en beta</>
                  )}
                  {plan === 'Plus' && (
                    <><s>100 USD</s> - Hoy, 50 USD por lanzamiento en beta</>
                  )}
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: 'center' }}>
                <Button variant="contained" onClick={() => handleSelection(plan)}>Seleccionar</Button>
              </CardActions>
            </Card>
          ))}
        </Box>
      </Box>
    </Modal>
  );
};

const modalStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: 600,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

export default PlanSelectionModal;
