import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Checkbox, FormGroup, FormControlLabel, Typography, Card } from '@mui/material';

// Lista de puertos
const ports = [
  'Nogales', 'Brownsville', 'Eagle Pass', 'Hidalgo',
  'Laredo', 'El Paso', 'Calexico', 'San Ysidro'
];

// Función para obtener un puerto aleatorio
const getRandomPort = (availablePorts) => availablePorts[Math.floor(Math.random() * availablePorts.length)];

// Componente TerminalSimulator
const TerminalSimulator = ({ isDemo }) => {
  const [output, setOutput] = useState('');
  const [isRunning, setIsRunning] = useState(false);
  const [selectedPorts, setSelectedPorts] = useState(() => {
    const savedPorts = localStorage.getItem('selectedPorts');
    return savedPorts ? JSON.parse(savedPorts) : ports;
  });
  const [selectAll, setSelectAll] = useState(selectedPorts.length === ports.length);

  // Referencia para el contenedor de la terminal
  const terminalRef = useRef(null);

  // Función para desplazar la terminal automáticamente hacia abajo
  const scrollToBottom = () => {
    if (terminalRef.current) {
      terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
    }
  };

  // Cursor parpadeante
  const [cursorVisible, setCursorVisible] = useState(true);
  useEffect(() => {
    const cursorInterval = setInterval(() => {
      setCursorVisible((prev) => !prev);
    }, 500);
    return () => clearInterval(cursorInterval);
  }, []);

  // Función para simular el efecto de escritura rápida
  const typeEffect = async (text, delay = 100) => {
    for (let i = 0; i < text.length; i++) {
      setOutput((prevOutput) => prevOutput + text[i]);
      await new Promise((resolve) => setTimeout(resolve, delay));
      scrollToBottom();
    }
    setOutput((prevOutput) => prevOutput + '\n');
    scrollToBottom();
  };

  // Función para simular barra de progreso con asteriscos y tiempo aleatorio
  const progressBar = async (length = 10) => {
    for (let i = 1; i <= length; i++) {
      setOutput((prevOutput) => prevOutput + '*'.repeat(i));
      await new Promise((resolve) => setTimeout(resolve, Math.random() * 1500));
      setOutput((prevOutput) => prevOutput + '\n');
      scrollToBottom();
    }
  };

  // Función para simular la barra de progreso de porcentaje
  const percentageProgressBar = async () => {
    const total = 20;
    for (let i = 0; i <= total; i++) {
      const percentage = (i * 5).toString().padStart(3, '0');
      setOutput((prevOutput) => prevOutput + `${percentage}% ` + '#'.repeat(i) + '\n');
      await new Promise((resolve) => setTimeout(resolve, Math.random() * 800));
      scrollToBottom();
    }
  };

  // Función para simular la secuencia de conexión con 'Trying to connect...'
  const simulateConnectionSequence = async (nextPort) => {
    await typeEffect(`Connecting to ${nextPort}...`, 50);
    if (Math.random() < 0.5) {
      await typeEffect(`Connected to ${nextPort}...`, 50);
      return;
    }
    const tryConnectCount = Math.floor(Math.random() * 3) + 1;
    for (let i = 0; i < tryConnectCount; i++) {
      await typeEffect('Trying to connect...', 50);
      await new Promise((resolve) => setTimeout(resolve, Math.random() * 4000));
    }
    await typeEffect(`Connected to ${nextPort}...`, 50);
  };

  // Función para ejecutar el simulador
  const runSimulator = async () => {
    // Verificar si hay conexión a Internet antes de ejecutar la simulación
    if (!navigator.onLine) {
      alert('No hay conexión a Internet. Por favor, conéctese para poder continuar.');
      return;
    }

    setIsRunning(true);
    setOutput('');

    if (isDemo) {
      await typeEffect('Esta es una demostración. Su plan es Básico o estamos a la espera de pago.', 50);
    }

    await typeEffect('Connecting to CBPONE.../consulting...', 50);
    await new Promise((resolve) => setTimeout(resolve, Math.random() * 3000));
    await typeEffect('Connected', 50);
    await new Promise((resolve) => setTimeout(resolve, Math.random() * 3000));
    await typeEffect('Starting script...', 50);
    await new Promise((resolve) => setTimeout(resolve, Math.random() * 3000));
    await typeEffect('Script started...', 50);

    let previousPort = '';

    for (let port of selectedPorts) {
      if (previousPort) {
        await typeEffect(`Disconnecting from ${previousPort}...`, 50);
        await typeEffect(`Disconnected from ${previousPort}...`, 50);
      }

      await simulateConnectionSequence(port);
      await typeEffect(`Consulting ${port}...`, 50);
      await progressBar(5);

      previousPort = port;

      if (Math.random() < 0.3) {
        await simulateWaiting();
      }
    }

    await typeEffect('Gathering data...', 50);
    await typeEffect('All data have been collected.', 50);
    await typeEffect('Measuring probabilities...', 50);
    await percentageProgressBar();

    await typeEffect('Done!', 50);
    await typeEffect(`The best port to schedule an appointment for your registration number is ${getRandomPort(selectedPorts)}`, 50);

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString();
    const formattedTime = currentDate.toLocaleTimeString();
    await typeEffect(`Date: ${formattedDate}`, 50);
    await typeEffect(`Time: ${formattedTime}`, 50);
    await typeEffect(`...end`, 50);

    if (isDemo) {
      await typeEffect('Este pronóstico no tiene ningún valor, ya que no se consultó ningún servidor, hemos corrido solo una demostración.', 50);
    }

    setIsRunning(false);
    localStorage.setItem('selectedPorts', JSON.stringify(selectedPorts));
  };

  // Función para simular los mensajes de 'Waiting...'
  const simulateWaiting = async () => {
    const waitCount = Math.floor(Math.random() * 3) + 1;
    for (let i = 0; i < waitCount; i++) {
      await typeEffect('Waiting...', 50);
      await new Promise((resolve) => setTimeout(resolve, Math.random() * 4000));
    }
  };

  // Manejar la selección de puertos
  const handlePortChange = (port) => {
    if (selectedPorts.includes(port)) {
      setSelectedPorts(selectedPorts.filter((p) => p !== port));
      setSelectAll(false);
    } else {
      const updatedPorts = [...selectedPorts, port];
      setSelectedPorts(updatedPorts);
      if (updatedPorts.length === ports.length) {
        setSelectAll(true);
      }
    }
  };

  // Manejar la selección de todos los puertos
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedPorts([]);
    } else {
      setSelectedPorts(ports);
    }
    setSelectAll(!selectAll);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4, width: '100%' }}>
      {/* Disclaimer */}
      <Typography
        variant="body2"
        sx={{ textAlign: 'center', color: '#f00', mb: 2, maxWidth: '800px' }}
      >
        Importante: El uso de esta terminal no asegura un adelanto en el plazo de que su cita sea asignada. Es solo una proyección simulada de la mejor probabilidad de solicitud de su cita en un puerto determinado en un momento dado.
      </Typography>

      {/* Selección de puertos */}
      <Card sx={{ bgcolor: '#f9f9f9', p: 3, borderRadius: 2, mb: 2, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', maxWidth: '800px', width: '90%' }}>
        <Typography variant="body1" sx={{ mb: 2, textAlign: 'center' }}>
          Seleccione sus puertos de preferencia para correr la estadística:
        </Typography>
        <FormGroup>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
              }
              label="Todos los Puertos"
              sx={{ textAlign: 'center', justifyContent: 'center', width: '100%', display: 'flex' }}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1, gap: 1 }}>
            {ports.slice(0, 4).map((port) => (
              <FormControlLabel
                key={port}
                control={
                  <Checkbox
                    checked={selectedPorts.includes(port)}
                    onChange={() => handlePortChange(port)}
                  />
                }
                label={port}
                sx={{ width: '20%' }}
              />
            ))}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
            {ports.slice(4).map((port) => (
              <FormControlLabel
                key={port}
                control={
                  <Checkbox
                    checked={selectedPorts.includes(port)}
                    onChange={() => handlePortChange(port)}
                  />
                }
                label={port}
                sx={{ width: '20%' }}
              />
            ))}
          </Box>
        </FormGroup>
      </Card>

      {/* Terminal */}
      <Box
        sx={{
          position: 'relative',
          bgcolor: '#000',
          color: '#0f0',
          p: 2,
          borderRadius: 2,
          fontFamily: 'monospace',
          width: '83vw',
          height: '50vh',
          maxWidth: '100%',
          maxHeight: '100%',
          minWidth: '280px',
          minHeight: '300px',
          overflowY: 'auto',
          mb: 10,
          whiteSpace: 'pre-wrap',
          border: '2px solid #0a0',
        }}
        ref={terminalRef}
      >
        {/* Mensaje superpuesto cuando está en modo demo */}
        {isDemo && isRunning && (
          <Box
            sx={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              bgcolor: 'rgba(0, 0, 0, 0.4)',
              color: '#fff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontWeight: 'bold',
              zIndex: 10,
            }}
          >
            Esto es una demostración. El resultado no tiene ningún valor.
          </Box>
        )}

        {/* Botón de ejecución antes de la salida del simulador */}
        <Button
          variant="contained"
          onClick={runSimulator}
          disabled={isRunning}
          sx={{
            bgcolor: '#0f0',
            color: '#000',
            mb: 2,
            '&:hover': { bgcolor: '#0a0' },
            display: isRunning ? 'none' : 'block',
          }}
        >
          Run
        </Button>

        {/* Muestra la salida del simulador */}
        <Box sx={{ whiteSpace: 'pre-wrap' }}>
          {output}
          {cursorVisible && <span style={{ fontWeight: 'bold' }}>_</span>}
        </Box>
      </Box>
    </Box>
  );
};

export default TerminalSimulator;
