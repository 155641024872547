// ContactModal.js
import React, { useState } from 'react';
import { Box, Button, Modal, TextField, Typography, Backdrop, Fade } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const ContactModal = ({ open, handleClose }) => {
  const [name, setName] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [emailError, setEmailError] = useState(false);

  // URL de la API de Google Apps Script
  const GOOGLE_SCRIPT_URL = 'https://script.google.com/macros/s/AKfycbwcNwbZ79j7HH19YEKRKm8X3aNLr_j3GcCEVu1wLcLkEfKEIaid3ZqxYfuPAlZ33sIK/exec'; // Reemplaza con la URL de tu script de Google Apps

  // Validar el formato del correo
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  // Manejar el envío del formulario usando fetch con no-cors
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setEmailError(true);
      return;
    }

    try {
      // Enviar datos a Google Sheets a través del script sin CORS
      await fetch(GOOGLE_SCRIPT_URL, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          whatsapp,
          email,
          message,
        }),
      });

      // Limpiar campos y cerrar modal
      setName('');
      setWhatsapp('');
      setEmail('');
      setMessage('');
      handleClose();
      alert('Mensaje enviado exitosamente (no se puede confirmar la respuesta, ya que se usó no-cors).');
    } catch (error) {
      console.error('Error al enviar los datos:', error);
      alert('Hubo un error al enviar el formulario. Por favor, inténtalo de nuevo.');
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 350,
            maxHeight: '90vh',
            overflowY: 'auto',
            bgcolor: 'rgba(0, 0, 0, 0.7)',
            borderRadius: 2,
            border: '1px solid rgba(255, 255, 255, 0.2)',
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.7)',
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: '#fff',
          }}
        >
          <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
            Contáctanos
          </Typography>

          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <TextField
              fullWidth
              variant="outlined"
              label="Nombres"
              value={name}
              onChange={(e) => setName(e.target.value)}
              sx={{
                mb: 2,
                bgcolor: '#444',
                borderRadius: 1,
                input: { color: '#fff' },
                label: { color: '#bbb' },
              }}
            />

            {/* Campo de WhatsApp con PhoneInput */}
            <Box sx={{ mb: 2 }}>
              <PhoneInput
                country={'mx'}
                value={whatsapp}
                onChange={setWhatsapp}
                placeholder="WhatsApp"
                inputStyle={{
                  width: '100%',
                  height: '56px',
                  fontSize: '16px',
                  borderRadius: '4px',
                  border: '1px solid #ccc',
                  paddingLeft: '48px',
                  backgroundColor: '#444',
                  color: '#fff',
                }}
                buttonStyle={{
                  border: 'none',
                  backgroundColor: 'transparent',
                }}
                containerStyle={{ width: '100%' }}
                enableAreaCodes={true}
                disableCountryCode={false}
                disableDropdown={false}
                countryCodeEditable={true}
              />
            </Box>

            <TextField
              fullWidth
              variant="outlined"
              label="Email"
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                if (emailError) setEmailError(false);
              }}
              onBlur={() => setEmailError(!validateEmail(email))}
              error={emailError}
              helperText={emailError ? 'Formato de correo inválido' : ''}
              sx={{
                mb: 2,
                bgcolor: '#444',
                borderRadius: 1,
                input: { color: '#fff' },
                label: { color: '#bbb' },
              }}
            />

            <TextField
              fullWidth
              variant="outlined"
              label="Mensaje"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              multiline
              rows={4}
              sx={{
                mb: 2,
                bgcolor: '#444',
                borderRadius: 1,
                textarea: { color: '#fff' },
                label: { color: '#bbb' },
              }}
            />

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ mt: 1, bgcolor: '#555', color: '#fff', '&:hover': { bgcolor: '#666' } }}
              endIcon={<SendIcon />}
            >
              Enviar
            </Button>
          </form>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ContactModal;
