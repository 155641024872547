import React, { useState, useEffect, useRef } from 'react';
import { Container, Typography, Box, Paper, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, useMediaQuery, useTheme } from '@mui/material';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import FlagIcon from '@mui/icons-material/Flag';
import HandshakeIcon from '@mui/icons-material/Handshake';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import StatsCounter from '../components/StatsCounter';
import ServicesCards from '../components/ServicesCards'; 
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import '../index.css';

const Home = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isVerySmallScreen = useMediaQuery('(max-width: 360px)');
  const [showArrow, setShowArrow] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [lastScrollTime, setLastScrollTime] = useState(Date.now());
  const [isMuted, setIsMuted] = useState(true);
  const [openSessionModal, setOpenSessionModal] = useState(false);
  const [isUserLogged, setIsUserLogged] = useState(false); // Estado para verificar sesión activa
  const videoRef = useRef(null);
  const cardsRef = useRef([]);
  const navigate = useNavigate();

  // Verificar si hay una sesión activa al cargar la página
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setOpenSessionModal(true);
        setIsUserLogged(true); // Marcar sesión como activa
      }
    });

    // Limpiar el listener al desmontar el componente
    return () => unsubscribe();
  }, []);

  // Confirmar la sesión actual
  const handleSessionConfirmation = (confirm) => {
    setOpenSessionModal(false);
    if (confirm) {
      navigate('/profile'); // Redirigir al perfil si se confirma la sesión
    } else {
      handleLogout(); // Cerrar sesión si el usuario no desea continuar
    }
  };

  // Cerrar sesión del usuario
  const handleLogout = async () => {
    try {
      await auth.signOut();
      localStorage.clear();
      sessionStorage.clear();
      setIsUserLogged(false); // Marcar sesión como cerrada
      navigate('/'); // Redirigir al inicio
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
    }
  };

  // Verificar la posición del scroll y el final de la página
  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    const windowHeight = window.innerHeight;
    const footerHeight = document.getElementById('footer')?.offsetHeight || 0;
    const documentHeight = document.body.scrollHeight;
    const scrolledToBottom = scrollTop + windowHeight >= documentHeight - footerHeight;

    if (scrolledToBottom) {
      setIsAtBottom(true);
      setShowArrow(false); // Ocultar flecha al llegar al final
    } else {
      setIsAtBottom(false);
      setShowArrow(false); // Ocultar flecha durante el scroll
      setLastScrollTime(Date.now()); // Actualizar tiempo del último scroll
    }
  };

  // Mostrar aviso al cerrar la ventana si hay una sesión activa
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isUserLogged) {
        event.preventDefault();
        event.returnValue = ''; // Mostrar aviso en la mayoría de los navegadores
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [isUserLogged]);

  // Verificar la inactividad del scroll para mostrar la flecha
  useEffect(() => {
    const checkInactivity = setInterval(() => {
      if (Date.now() - lastScrollTime >= 8000 && !isAtBottom) {
        setShowArrow(true); // Mostrar flecha después de 8 segundos de inactividad
        setTimeout(() => setShowArrow(false), 3000); // Ocultar flecha después de 3 segundos
      }
    }, 1000);

    return () => clearInterval(checkInactivity);
  }, [lastScrollTime, isAtBottom]);

  // Agregar el evento de scroll
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Alternar el estado del audio del video
  const toggleMute = () => {
    setIsMuted(!isMuted);
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
    }
  };

  // Determinar la fuente del video en función del tamaño de la pantalla
  const videoSrc = isSmallScreen ? "/Quienessomossmall.mp4" : "/Quienessomos.mp4";

  // Animar elementos al hacer scroll usando Intersection Observer
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in');
          }
        });
      },
      { threshold: 0.1 }
    );

    cardsRef.current.forEach((card) => {
      if (card) observer.observe(card);
    });

    return () => {
      if (cardsRef.current.length) {
        cardsRef.current.forEach((card) => {
          if (card) observer.unobserve(card);
        });
      }
    };
  }, []);

  const cardsData = [
    {
      title: '¿Quiénes somos?',
      description: 'Somos una fundación sin fines de lucro dedicada a proporcionar información precisa, actualizada y contrastada sobre los procesos migratorios y aduaneros relacionados con la solicitud de citas mediante la aplicación CBPONE.',
      icon: <LocationCityIcon sx={{ fontSize: 50, color: '#3f51b5', mb: 2 }} />,
    },
    {
      title: 'Nuestra misión',
      description: 'Proteger a los usuarios frente a posibles estafas y malas prácticas, proporcionando información veraz y contrastada, además de ofrecer una asesoría integral que facilita la formalización de registros y optimiza la solicitud de citas a través de la aplicación CBPONE.',
      icon: <FlagIcon sx={{ fontSize: 50, color: '#3f51b5', mb: 2 }} />,
    },
    {
      title: 'Nuestro compromiso',
      description: 'Promover la educación y la protección de los usuarios, reduciendo la brecha de información y promoviendo un proceso migratorio más seguro, rápido y eficiente para todos.',
      icon: <HandshakeIcon sx={{ fontSize: 50, color: '#3f51b5', mb: 2 }} />,
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 1, mb: 3, position: 'relative' }}>
      {/* Modal de confirmación de sesión */}
      <Dialog
        open={openSessionModal}
        PaperProps={{
          sx: {
            bgcolor: 'rgba(0, 0, 0, 0.8)',
            color: '#fff',
            borderRadius: 2,
            p: 2,
          },
        }}
      >
        <DialogTitle>Continuar Sesión</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: '#bbb' }}>
            Se detectó una sesión activa. ¿Deseas continuar con ella?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleSessionConfirmation(false)}
            sx={{ color: '#fff', bgcolor: '#d32f2f', '&:hover': { bgcolor: '#b71c1c' } }}
          >
            No
          </Button>
          <Button
            onClick={() => handleSessionConfirmation(true)}
            sx={{ color: '#fff', bgcolor: '#1976d2', '&:hover': { bgcolor: '#1565c0' } }}
          >
            Sí
          </Button>
        </DialogActions>
      </Dialog>

      {/* Video de introducción */}
      <Box sx={{ mb: 1, position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <video
          ref={videoRef}
          src={videoSrc}
          autoPlay
          loop
          muted={isMuted}
          controls
          style={{
            borderRadius: '8px',
            width: isSmallScreen ? '90%' : '60%',
            maxWidth: isSmallScreen ? '320px' : '600px',
            border: '2px solid #3f51b5'
          }}
        >
          Tu navegador no soporta el elemento de video.
        </video>

        <IconButton
          onClick={toggleMute}
          sx={{
            position: 'absolute',
            top: 16,
            right: 16,
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            color: '#fff',
            zIndex: 2,
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.8)' },
          }}
        >
          {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
        </IconButton>
      </Box>

      {/* Mostrar el componente de contadores */}
      <Box
        sx={{
          mb: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          gap: 1,
        }}
      >
        <StatsCounter
          sx={{
            flexShrink: 0,
            transform: isVerySmallScreen ? 'scale(0.6)' : isSmallScreen ? 'scale(0.8)' : 'scale(1)',
            transition: 'transform 0.3s ease',
          }}
        />
      </Box>

      {/* Tarjetas de información */}
      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 4, mb: 4 }}>
        {cardsData.map((card, index) => (
          <Paper
            key={index}
            ref={(el) => (cardsRef.current[index] = el)}
            className="fade-out"
            elevation={3}
            sx={{
              width: 300,
              p: 3,
              textAlign: 'justify',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 2,
              opacity: 0,
              transform: 'translateY(20px)',
              transition: 'opacity 0.5s, transform 0.5s',
            }}
          >
            {card.icon}
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
              {card.title}
            </Typography>
            <Typography 
              variant="body2" 
              color="textSecondary"
              sx={{ mb: 2 }}
            >
              {card.description}
            </Typography>
          </Paper>
        ))}
      </Box>

      {/* Flecha hacia abajo */}
      {showArrow && !isAtBottom && (
        <IconButton
          sx={{
            position: 'fixed',
            bottom: 110,
            left: '50%',
            transform: 'translateX(-50%)',
            animation: 'flash 1s infinite',
            backgroundColor: 'rgba(128, 128, 128, 0.7)',
            color: 'rgba(0, 0, 0, 0.7)',
            zIndex: 1000,
            ':hover': { backgroundColor: 'rgba(128, 128, 128, 0.5)' },
          }}
        >
          <ArrowDownwardIcon sx={{ fontSize: 40 }} />
        </IconButton>
      )}

      {/* Título para servicios */}
      <Typography
        variant="h5"
        sx={{
          mt: 2,
          mb: 2,
          fontWeight: 'bold',
          textAlign: 'center',
          color: '#333',
        }}
      >
        Estos son los servicios que ofrecemos a nuestros usuarios registrados:
      </Typography>

      {/* Componente de tarjetas de servicios */}
      <ServicesCards />

      {/* Placeholder para el footer */}
      <Box id="footer" sx={{ height: '100px', backgroundColor: '#f0f0f0', mt: 4 }} />
    </Container>
  );
};

export default Home;
