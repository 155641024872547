import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, TextField, Typography, Backdrop, Fade, Divider, IconButton, InputAdornment, Alert } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material'; 
import { PersonAdd } from '@mui/icons-material';
import FacebookIcon from '@mui/icons-material/Facebook';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase'; 
import { getDatabase, ref, set } from 'firebase/database';
import { createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth';
import PlanSelectionModal from './PlanSelectionModal';

// Función para generar un código único de 12 caracteres
const generateUniqueCode = () => {
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*';
  let code = '';
  for (let i = 0; i < 12; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    code += charset[randomIndex];
  }
  return code;
};

// Definición de estilos del modal
const modalStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 350,
  maxHeight: '90vh',
  overflowY: 'auto',
  bgcolor: 'rgba(0, 0, 0, 0.7)',
  borderRadius: 2,
  border: '1px solid rgba(255, 255, 255, 0.2)',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.7)',
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: '#fff',
};

// Función para validar el formato del correo
const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

// Función para validar la longitud mínima de la contraseña
const validatePassword = (password) => password.length >= 6;

const RegisterModal = ({ open, handleClose, openLoginModal }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); 
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [emailError, setEmailError] = useState(false); 
  const [passwordError, setPasswordError] = useState(false); 
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [authError, setAuthError] = useState(null);
  const [isPlanModalOpen, setIsPlanModalOpen] = useState(false); 
  const [isProfileBlocked, setIsProfileBlocked] = useState(false); 

  const navigate = useNavigate();

  // Resetear campos y errores
  const resetFields = () => {
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setEmailError(false);
    setPasswordError(false);
    setConfirmPasswordError(false);
    setAuthError(null);
  };

  // Manejar el registro con correo y contraseña
  const handleRegister = async (e) => {
    e.preventDefault();
    setAuthError(null); 

    if (!validateEmail(email)) {
      setEmailError(true);
      return;
    }

    if (!validatePassword(password)) {
      setPasswordError(true);
      return;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError(true);
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Generar un código único para el usuario
      const uniqueCode = generateUniqueCode();

      // Asignar rol de usuario por defecto y guardar en la base de datos
      const db = getDatabase();
      await set(ref(db, 'users/' + user.uid), {
        email: user.email,
        plan: 'Básico',
        role: 'usuario',  // Por defecto, el rol es "usuario"
        uniqueCode: uniqueCode, // Código único generado
      });

      resetFields();
      handleClose(); 
      setIsPlanModalOpen(true); 
      setIsProfileBlocked(true); 
      navigate('/profile'); 
    } catch (error) {
      setAuthError('Error al registrar el usuario. Intenta de nuevo.');
    }
  };

  // Manejar el registro con Google
  const handleGoogleRegister = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const userCredential = await signInWithPopup(auth, provider);
      const user = userCredential.user;

      // Generar un código único para el usuario
      const uniqueCode = generateUniqueCode();

      // Asignar rol de usuario por defecto y guardar en la base de datos
      const db = getDatabase();
      await set(ref(db, 'users/' + user.uid), {
        email: user.email,
        plan: 'Básico',
        role: 'usuario',
        uniqueCode: uniqueCode,
      });

      resetFields();
      handleClose(); 
      setIsPlanModalOpen(true); 
      setIsProfileBlocked(true); 
      navigate('/profile'); 
    } catch (error) {
      setAuthError('Error al registrar con Google. Intenta de nuevo.');
    }
  };

  // Manejar el registro con Facebook
  const handleFacebookRegister = async () => {
    const provider = new FacebookAuthProvider();
    try {
      const userCredential = await signInWithPopup(auth, provider);
      const user = userCredential.user;

      // Generar un código único para el usuario
      const uniqueCode = generateUniqueCode();

      // Asignar rol de usuario por defecto y guardar en la base de datos
      const db = getDatabase();
      await set(ref(db, 'users/' + user.uid), {
        email: user.email,
        plan: 'Básico',
        role: 'usuario',
        uniqueCode: uniqueCode,
      });

      resetFields();
      handleClose(); 
      setIsPlanModalOpen(true); 
      setIsProfileBlocked(true); 
      navigate('/profile'); 
    } catch (error) {
      setAuthError('Error al registrar con Facebook. Intenta de nuevo.');
    }
  };

  // Alternar la visibilidad de la contraseña
  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword((prev) => !prev);

  useEffect(() => {
    if (!isPlanModalOpen) {
      setIsProfileBlocked(false);
    }
  }, [isPlanModalOpen]);

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          resetFields();
          handleClose();
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <Box sx={modalStyles}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
              Regístrate
            </Typography>

            {authError && (
              <Alert severity="error" sx={{ mb: 2, bgcolor: '#333', color: '#fff' }}>
                {authError}
              </Alert>
            )}

            <form onSubmit={handleRegister} style={{ width: '100%' }}>
              <TextField
                fullWidth
                variant="outlined"
                label="Email"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (emailError) setEmailError(false);
                }}
                onBlur={() => setEmailError(!validateEmail(email))}
                error={emailError}
                helperText={emailError ? 'Formato de correo inválido' : ''}
                sx={{ mb: 2, bgcolor: '#444', borderRadius: 1, input: { color: '#fff' }, label: { color: '#bbb' } }}
              />
              <TextField
                fullWidth
                variant="outlined"
                label="Contraseña"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  if (passwordError) setPasswordError(false);
                }}
                onBlur={() => setPasswordError(!validatePassword(password))}
                error={passwordError}
                helperText={passwordError ? 'La contraseña debe tener al menos 6 caracteres' : ''}
                sx={{ mb: 2, bgcolor: '#444', borderRadius: 1, input: { color: '#fff' }, label: { color: '#bbb' } }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={togglePasswordVisibility}
                        edge="end"
                        sx={{ color: '#fff' }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                variant="outlined"
                label="Confirmar Contraseña"
                type={showConfirmPassword ? 'text' : 'password'}
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  if (confirmPasswordError) setConfirmPasswordError(false);
                }}
                onBlur={() => setConfirmPasswordError(password !== confirmPassword)}
                error={confirmPasswordError}
                helperText={confirmPasswordError ? 'Las contraseñas no coinciden' : ''}
                sx={{ mb: 2, bgcolor: '#444', borderRadius: 1, input: { color: '#fff' }, label: { color: '#bbb' } }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={toggleConfirmPasswordVisibility}
                        edge="end"
                        sx={{ color: '#fff' }}
                      >
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                type="submit"
                variant="outlined"
                fullWidth
                sx={{
                  mt: 1,
                  borderColor: '#fff',
                  color: '#000',
                  bgcolor: '#fff',
                  fontWeight: 'bold',
                  '&:hover': {
                    bgcolor: '#000',
                    borderColor: '#fff',
                    color: '#fff',
                  },
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <PersonAdd />
                Registrarse
              </Button>
            </form>

            <Divider sx={{ width: '100%', my: 2, bgcolor: '#bbb' }}>O</Divider>

            <Button
              variant="contained"
              fullWidth
              onClick={handleGoogleRegister}
              sx={{
                mb: 1,
                bgcolor: '#fff',
                color: '#4285F4',
                textTransform: 'none',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
                '&:hover': { bgcolor: '#f5f5f5' },
              }}
            >
              <img src={`${process.env.PUBLIC_URL}/Logo-google-icon.png`} alt="Google Icon" style={{ width: 24, height: 24 }} />
              <span>Regístrate con Google</span>
            </Button>

            <Button
              variant="contained"
              fullWidth
              onClick={handleFacebookRegister}
              sx={{
                mb: 1,
                bgcolor: '#1877F2',
                color: '#fff',
                textTransform: 'none',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
                '&:hover': { bgcolor: '#1558b0' },
              }}
            >
              <FacebookIcon />
              <span>Regístrate con <strong>Facebook</strong></span>
            </Button>

            <Typography
              variant="body2"
              sx={{ mt: 2, color: '#bbb' }}
            >
              ¿Ya tienes cuenta?{' '}
              <span
                style={{ color: '#fff', textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => {
                  resetFields();
                  handleClose();
                  openLoginModal();
                }}
              >
                Inicia sesión
              </span>
            </Typography>
          </Box>
        </Fade>
      </Modal>

      {/* Modal de selección de plan */}
      <PlanSelectionModal open={isPlanModalOpen} onClose={() => setIsPlanModalOpen(false)} />
    </>
  );
};

export default RegisterModal;
