import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Icon, useTheme, useMediaQuery } from '@mui/material';
import CountUp from 'react-countup';
import CelebrationIcon from '@mui/icons-material/Celebration';
import { getDatabase, ref, get, set, increment } from 'firebase/database';

const StatsCounter = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));  // Detectar pantallas pequeñas

  // Estado de contadores
  const [visits, setVisits] = useState(0);
  const [registrations, setRegistrations] = useState(350);  // Número inicial simulado de registrados
  const [appointments, setAppointments] = useState(480);  // Inicia en 480
  const [isFlashing, setIsFlashing] = useState(false);
  const [isIlluminated, setIsIlluminated] = useState(false);

  useEffect(() => {
    const db = getDatabase();

    // Manejar visitas totales
    const visitsRef = ref(db, 'stats/visits');
    get(visitsRef).then((snapshot) => {
      let visitCount = snapshot.exists() ? snapshot.val() : 0;
      visitCount += 1;

      // Actualizar el estado local de visitas
      setVisits(visitCount);

      // Actualizar visitas en la base de datos
      set(visitsRef, visitCount);
    });

    // Obtener el número total de registros
    const usersRef = ref(db, 'users');
    get(usersRef).then((snapshot) => {
      if (snapshot.exists()) {
        const usersData = snapshot.val();
        const totalRegistrations = Object.keys(usersData).length;

        // Actualizar el estado local de registros
        setRegistrations(350 + totalRegistrations);
      }
    });
  }, []);

  // Activar el parpadeo seguido de iluminación cuando el contador de citas otorgadas alcance su número final
  const handleAppointmentsEnd = () => {
    setIsFlashing(true);

    // Parpadeo inicial durante 3 segundos (3 parpadeos)
    setTimeout(() => {
      setIsFlashing(false);
      setIsIlluminated(true);  // Marcar la iluminación como completa
    }, 3000);  // Parpadeo dura 3 segundos
  };

  // Estilo responsivo de los contadores
  const counterStyle = {
    padding: isSmallScreen ? 1 : 2,
    minWidth: isSmallScreen ? 100 : 150,
    textAlign: 'center',
    borderRadius: 2,
  };

  return (
    <Paper
      sx={{
        padding: isSmallScreen ? 2 : 4,
        backgroundColor: '#f5f5f5',
        borderRadius: 4,
        border: '2px solid #444',
        boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.4)',
        marginBottom: 4,
        textAlign: 'center',
        marginTop: 5,
        transform: isSmallScreen ? 'scale(0.9)' : 'scale(1)',  // Escala para pantallas pequeñas
        transition: 'transform 0.3s ease',
      }}
    >
      <Typography variant={isSmallScreen ? 'h6' : 'h5'} sx={{ fontWeight: 'bold', marginBottom: 3 }}>
        Los números nos respaldan:
      </Typography>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: isSmallScreen ? 2 : 3,
          mt: 4,
          flexWrap: 'wrap',
        }}
      >
        {/* Contador de visitas */}
        <Paper elevation={3} sx={counterStyle}>
          <Typography variant={isSmallScreen ? 'body1' : 'h6'}>Visitas</Typography>
          <Typography variant={isSmallScreen ? 'h5' : 'h4'} color="primary" sx={{ mt: 1 }}>
            <CountUp start={0} end={visits} duration={2} />
          </Typography>
        </Paper>

        {/* Contador de registrados */}
        <Paper elevation={3} sx={counterStyle}>
          <Typography variant={isSmallScreen ? 'body1' : 'h6'}>Registrados</Typography>
          <Typography variant={isSmallScreen ? 'h5' : 'h4'} color="secondary" sx={{ mt: 1 }}>
            <CountUp start={0} end={registrations} duration={2} />
          </Typography>
        </Paper>

        {/* Contador de citas otorgadas con efecto de parpadeo e iluminación */}
        <Paper
          elevation={3}
          sx={{
            ...counterStyle,
            position: 'relative',
            animation: isFlashing ? 'flash 0.5s ease-in-out 6' : 'none',
            border: isIlluminated ? '2px solid orange' : '1px solid #ccc',
            boxShadow: isIlluminated ? '0 0 20px 5px rgba(255, 165, 0, 0.7)' : 'none',
          }}
        >
          <Typography variant={isSmallScreen ? 'body1' : 'h6'}>Citas Otorgadas</Typography>

          {/* Ícono de celebración animado */}
          {isIlluminated && (
            <Icon
              component={CelebrationIcon}
              sx={{
                position: 'absolute',
                top: -20,
                right: -20,
                fontSize: isSmallScreen ? 20 : 30,
                color: 'orange',
                animation: 'bounce 1s infinite',
              }}
            />
          )}

          <Typography variant={isSmallScreen ? 'h5' : 'h4'} color="success" sx={{ mt: 1 }}>
            <CountUp
              start={0}
              end={appointments}  // Llega al número final especificado
              duration={3}
              onEnd={handleAppointmentsEnd}  // Activar la animación al llegar al número final
            />
          </Typography>
        </Paper>
      </Box>
    </Paper>
  );
};

export default StatsCounter;
