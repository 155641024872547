import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import ProfilePage from './pages/ProfilePage';
import WhatsAppButton from './components/WhatsAppButton';
import ProtectedRoute from './components/ProtectedRoute'; // Importa el componente de ruta protegida

const App = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Navbar />
      <div style={{ flex: 1 }}>
        <Routes>
          <Route path="/" element={<Home />} />
         
          
          {/* Ruta protegida para ProfilePage */}
          <Route path="/profile" element={<ProtectedRoute />}>
            <Route path="" element={<ProfilePage />} />
          </Route>
        </Routes>
      </div>
      <Footer />
      <WhatsAppButton />
    </div>
  );
};

export default App;
