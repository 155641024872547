// src/components/Puerto.js
import React, { useState } from 'react';
import { Box, Card, CardContent, CardActions, CardMedia, Typography, Button, Grid, Paper } from '@mui/material';
import PuertoMap from './PuertoMap'; // Importa el componente del mapa
import LocationOnIcon from '@mui/icons-material/LocationOn'; // Importa el icono de ubicación

const puertoData = [
  {
    name: 'Nogales',
    description: 'El puerto de Nogales tiene citas disponibles de forma adelantada gracias a la baja demanda en comparación con otros puertos...',
    fullDescription: 'El puerto de Nogales tiene citas disponibles de forma adelantada gracias a la baja demanda en comparación con otros puertos, lo que lo convierte en una buena opción para quienes buscan una menor espera. Este cruce fronterizo es importante para la exportación de productos agrícolas y manufacturados entre México y Estados Unidos. A pesar de ser menos congestionado, cuenta con controles aduaneros rigurosos que garantizan la seguridad del cruce.',
    pros: ['Disponibilidad para citas con menor espera', 'Buena conectividad', 'Infraestructura moderna'],
    cons: ['Tráfico local intenso', 'Control aduanero estricto'],
    mexicoAirport: { code: 'HMO', name: 'Aeropuerto Internacional de Hermosillo General Ignacio Pesqueira' },
    usAirport: { code: 'TUS', name: 'Tucson International Airport' },
    mexicoAddress: 'Nogales, Sonora, México',
    imageUrl: 'https://bloximages.chicago2.vip.townnews.com/nogalesinternational.com/content/tncms/assets/v3/editorial/4/8d/48d337a0-8c11-11ef-b947-078798a374f4/66fcd5a44260c.image.jpg?resize=400%2C267',
    dailyAppointments: '100',
  },
  {
    name: 'Brownsville',
    description: 'El puerto de Brownsville tiene alta demanda de citas y sufre saturación debido al elevado volumen de solicitudes...',
    fullDescription: 'El puerto de Brownsville es uno de los principales puntos de cruce entre Texas y México y se caracteriza por una alta demanda de citas que genera saturación. Aunque otorga muchas citas diarias, la entrega se ve afectada por la demanda, lo cual causa demoras. Además, hay muchas probabilidades de extorsión por parte de cárteles (pago de "piso"), lo cual representa un riesgo de seguridad. Sin embargo, sigue siendo un puerto eficiente y de gran relevancia para el comercio, operando las 24 horas.',
    pros: ['Alta eficiencia y operación las 24 horas', 'Gran capacidad de citas diarias', 'Relevancia comercial'],
    cons: [
      'Alta demanda y saturación en la entrega de citas',
      'Muchas probabilidades de extorsión por parte de cárteles (pago de "piso")',
      'Alta peligrosidad en el área'
    ],
    mexicoAirport: { code: 'MAM', name: 'Aeropuerto Internacional General Servando Canales de Matamoros' },
    usAirport: { code: 'BRO', name: 'Brownsville/South Padre Island International Airport' },
    mexicoAddress: 'Matamoros, Tamaulipas, México',
    imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/91/Brownsville_Gateway_Border_Inspection_Lanes.jpg/300px-Brownsville_Gateway_Border_Inspection_Lanes.jpg',
    dailyAppointments: '365',
  },
  {
    name: 'Eagle Pass',
    description: 'Eagle Pass tiene problemas logísticos que dificultan el acceso y limitan la disponibilidad de citas...',
    fullDescription: 'Eagle Pass es un puerto importante para la industria de Texas, pero presenta desafíos logísticos significativos debido a la falta de vuelos comerciales directos. Los vuelos solo están disponibles mediante avionetas desde Monterrey, lo cual es costoso y requiere pasaporte para abordar. Estos factores hacen que conseguir citas para CBPone sea complicado, aunque la menor saturación del puerto permite un acceso más fácil para quienes logran llegar.',
    pros: ['Conexión con carreteras principales', 'Menos saturación que otros puertos'],
    cons: ['Difícil acceso aéreo', 'Altos costos de vuelos', 'Requiere pasaporte para vuelos internos'],
    mexicoAirport: { code: 'PDS', name: 'Aeropuerto Internacional de Piedras Negras' },
    usAirport: { code: 'SAT', name: 'San Antonio International Airport' },
    mexicoAddress: 'Piedras Negras, Coahuila, México',
    imageUrl: 'https://www.eaglepasstx.gov/ImageRepository/Document?documentId=526',
    dailyAppointments: '60',
  },
  {
    name: 'Hidalgo',
    description: 'El puerto de Hidalgo presenta riesgos de seguridad debido a la actividad de grupos criminales en la región...',
    fullDescription: 'El puerto de Hidalgo es un cruce activo tanto para el comercio como para el turismo. Aunque tiene un fácil acceso y buen flujo de viajeros, la actividad de grupos criminales en la región representa un riesgo, incluyendo muchas probabilidades de extorsión por parte de cárteles (pago de "piso"). Las citas para CBPone están disponibles, pero en períodos de alta demanda, las esperas pueden ser prolongadas.',
    pros: ['Fácil acceso', 'Tránsito rápido', 'Opciones de vuelos accesibles'],
    cons: [
      'Largas filas durante las horas pico',
      'Muchas probabilidades de extorsión por parte de cárteles (pago de "piso")',
      'Alta peligrosidad en la zona'
    ],
    mexicoAirport: { code: 'REX', name: 'Aeropuerto Internacional General Lucio Blanco' },
    usAirport: { code: 'MFE', name: 'McAllen Miller International Airport' },
    mexicoAddress: 'Reynosa, Tamaulipas, México',
    imageUrl: 'https://www.elperiodicousa.com/wp-content/uploads/2023/12/Tex-Autobuses.jpg',
    dailyAppointments: '205',
  },
  {
    name: 'Laredo',
    description: 'Laredo es uno de los puertos más transitados y tiene alta demanda de citas y riesgos de seguridad...',
    fullDescription: 'El puerto de Laredo facilita gran parte del comercio internacional entre México y Estados Unidos y es uno de los más transitados del país. La alta demanda genera saturación en la entrega de citas y esto, junto con el tráfico pesado, puede dificultar el acceso. Además, existen muchas probabilidades de extorsión por parte de cárteles (pago de "piso"), lo cual representa un riesgo de seguridad. Esto hace que la programación de citas en CBPone sea un proceso desafiante y arriesgado.',
    pros: ['Alto volumen de comercio', 'Gran infraestructura', 'Vuelos accesibles desde ciudades principales'],
    cons: [
      'Tráfico intenso',
      'Procesos aduanales complicados',
      'Muchas probabilidades de extorsión por parte de cárteles (pago de "piso")',
      'Alta peligrosidad en el área'
    ],
    mexicoAirport: { code: 'NLD', name: 'Aeropuerto Internacional Quetzalcóatl' },
    usAirport: { code: 'LRD', name: 'Laredo International Airport' },
    mexicoAddress: 'Nuevo Laredo, Tamaulipas, México',
    imageUrl: 'https://s.hdnux.com/photos/01/33/77/56/24116136/3/ratio3x2_1920.jpg',
    dailyAppointments: '50',
  },
  {
    name: 'Paso del Norte',
    description: 'El puerto de Paso del Norte facilita tanto el comercio como el turismo gracias a su moderna infraestructura...',
    fullDescription: 'El puerto de El Paso, conocido como Paso del Norte, conecta Texas con Chihuahua y facilita tanto el comercio como el turismo. La infraestructura moderna y las facilidades para turistas hacen que sea una buena opción para quienes buscan un cruce eficiente. Aunque la demanda de citas es moderada, se recomienda programar con anticipación debido a los picos de costo y tráfico.',
    pros: ['Infraestructura moderna', 'Opciones de vuelos accesibles', 'Facilidades para turistas'],
    cons: ['Tráfico moderado', 'Altos costos en horarios pico'],
    mexicoAirport: { code: 'CJS', name: 'Aeropuerto Internacional Abraham González' },
    usAirport: { code: 'ELP', name: 'El Paso International Airport' },
    mexicoAddress: 'Ciudad Juárez, Chihuahua, México',
    imageUrl: 'https://kfoxtv.com/resources/media2/1280x720/1280/986/0x0/80/97b15e7c-9afe-4d63-9030-f20bc880faae-145_0210_frame_18.jpeg',
    dailyAppointments: '245',
  },
  {
    name: 'Calexico',
    description: 'Calexico es un cruce fronterizo popular debido a su acceso directo y facilidad para el comercio y el turismo...',
    fullDescription: 'Calexico es un cruce fronterizo entre California y Baja California que es popular debido a su acceso directo y facilidad para el comercio y el turismo. Sin embargo, durante las horas pico, las filas pueden ser muy largas, y la capacidad limitada de la aduana puede generar demoras adicionales. Es importante considerar estos factores al solicitar una cita a través de CBPone.',
    pros: ['Fácil acceso', 'Conexión directa con California', 'Acceso directo a Mexicali'],
    cons: ['Largas filas en horas pico', 'Espacio limitado en la aduana'],
    mexicoAirport: { code: 'MXL', name: 'Aeropuerto Internacional General Rodolfo Sánchez Taboada' },
    usAirport: { code: 'CXL', name: 'Calexico International Airport' },
    mexicoAddress: 'Mexicali, Baja California, México',
    imageUrl: 'https://pbs.twimg.com/media/E8mZqeiVgAM7x5j?format=jpg&name=large',
    dailyAppointments: '70',
  },
  {
    name: 'San Ysidro',
    description: 'San Ysidro es el cruce fronterizo terrestre más transitado del mundo y enfrenta alta demanda de citas...',
    fullDescription: 'San Ysidro es el cruce fronterizo terrestre más transitado del mundo, conectando San Diego con Tijuana. Este puerto ofrece una gran cantidad de citas diarias debido a su operación continua, pero la alta demanda y el tráfico intenso durante los fines de semana pueden causar largas demoras. Es importante tener en cuenta la saturación de las citas al programar a través de CBPone.',
    pros: ['El puerto más seguro', 'Alta disponibilidad de vuelos', 'Vuelos económicos', 'Operación continua', 'Alta capacidad de citas diarias'],
    cons: ['Largos tiempos de espera', 'Altos volúmenes de tráfico durante fines de semana', 'Demora en las citas debido a la alta demanda'],
    mexicoAirport: { code: 'TIJ', name: 'Aeropuerto Internacional General Abelardo L. Rodríguez' },
    usAirport: { code: 'SAN', name: 'San Diego International Airport' },
    mexicoAddress: 'Tijuana, Baja California, México',
    imageUrl: 'https://tvazteca.brightspotcdn.com/dims4/default/b6c52a8/2147483647/strip/true/crop/1200x682+0+0/resize/928x527!/format/webp/quality/90/?url=http%3A%2F%2Ftv-azteca-brightspot.s3.amazonaws.com%2Fee%2Fec%2F22544ac24f7380bfc1171ed81fa7%2Fpedwest.jpg',
    dailyAppointments: '395',
  },
];

const Puerto = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleExpandClick = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <Box sx={{ flexGrow: 1, p: 4 }}>
      <Typography variant="h4" sx={{ mb: 4, textAlign: 'center', color: '#000' }}>
        Puertos
      </Typography>
      <Box sx={{ mt: 4, mb: 4 }}>
  <Paper elevation={3} sx={{ p: 2 }}>
    <Typography variant="body1" align="justify" sx={{ color: '#000', mb: 2 }}>
      Seleccionar su puerto de entrada es una decisión muy importante que no debe tomarse a la ligera. Debe considerar no solo la rapidez con la que puede obtener una cita, sino también la seguridad personal de usted y su familia, la facilidad de movilidad, así como sus recursos económicos para afrontar posibles complicaciones al momento de obtener la cita.
    </Typography>
    <Typography variant="body1" align="justify" sx={{ color: '#000', mb: 2 }}>
      Nuestros estudios estadísticos, basados en encuestas realizadas a pie de fila en los puertos de entrada, muestran que cada puerto sigue la proporción anunciada por las autoridades: un 30% de las citas se asignan por antigüedad y un 70% por sorteo, con muy pocas variaciones entre los puertos. Sin embargo, debido a la demanda desigual, los puertos asignan las citas cronológicas en diferentes tramos de numeración. Esto significa que, si su número de registro es, por ejemplo, 172, mientras algunos puertos ya están otorgando citas para dicho tramo, otros puertos con mayor demanda pueden estar apenas alcanzando los tramos 168-169.
    </Typography>
    <Typography variant="body1" align="justify" sx={{ color: '#000' }}>
      En otras palabras, no todas las cronologías avanzan de igual manera en los distintos puertos. Es fundamental que no elija el puerto únicamente en función de la rapidez con la que podría recibir la cita. En cambio, debe evaluar cuidadosamente su seguridad y la de su familia, considerando si podría asumir los costos económicos, incluida la posibilidad de pagar extorsiones o enfrentar riesgos de seguridad derivados de la presencia de grupos delictivos. Todos los puertos entregan citas de manera cronológica; en algunos casos, el proceso es más rápido y en otros más lento, pero la seguridad y la viabilidad económica deben ser siempre las prioridades al elegir su puerto de entrada.
    </Typography>
  </Paper>
</Box>


      {/* Renderiza el componente del Mapa */}
      <Box sx={{ mb: 4 }}>
        <PuertoMap />
      </Box>

      <Grid container spacing={4}>
        {puertoData.map((puerto, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ maxWidth: 345, bgcolor: '#f5f5f5', borderRadius: 2, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
              {/* Imagen del Puerto */}
              <CardMedia
                component="img"
                alt={`Imagen de ${puerto.name}`}
                height="140"
                image={puerto.imageUrl}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div" sx={{ color: '#333' }}>
                  {puerto.name}
                </Typography>
                <Typography variant="body2" color="text.secondary" align="justify">
                  {expandedIndex === index ? puerto.fullDescription : puerto.description}
                </Typography>
                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 'bold' }}>Aeropuertos:</Typography>
                <Typography variant="body2" sx={{ color: '#000' }}>
                  <strong>México:</strong> {puerto.mexicoAirport.name} IATA: ({puerto.mexicoAirport.code})
                </Typography>
                <Typography variant="body2" sx={{ color: '#000' }}>
                  <strong>EE.UU.:</strong> {puerto.usAirport.name} IATA: ({puerto.usAirport.code})
                </Typography>
                <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 'bold' }}>Citas diarias aproximadas:</Typography>
                <Typography variant="body2" sx={{ color: '#000' }}>
                  {puerto.dailyAppointments}
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: 'center', px: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', gap: 1, width: '100%', px: 2 }}>
                  <Button
                    size="small"
                    onClick={() => handleExpandClick(index)}
                    sx={{
                      bgcolor: '#333',
                      color: '#fff',
                      textTransform: 'none',
                      borderRadius: 1,
                      px: 2,
                      py: 0.5,
                      '&:hover': { bgcolor: '#555' },
                    }}
                  >
                    {expandedIndex === index ? 'Leer menos' : 'Leer más'}
                  </Button>
                  <Button
                    size="small"
                    href={`https://www.google.com/maps?q=${puerto.mexicoAddress}`}
                    target="_blank"
                    startIcon={<LocationOnIcon />}
                    sx={{
                      bgcolor: '#6c757d', // Color gris para el botón
                      color: '#fff',
                      textTransform: 'none',
                      borderRadius: 1,
                      px: 2,
                      py: 0.5,
                      '&:hover': { bgcolor: '#5a6268' },
                    }}
                  >
                    Ver en Google Maps
                  </Button>
                </Box>
              </CardActions>
              <CardContent>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#444' }}>Pros:</Typography>
                <ul style={{ paddingLeft: '16px' }}>
                  {puerto.pros.map((pro, idx) => (
                    <li key={idx}>
                      <Typography variant="body2" color="text.secondary">{pro}</Typography>
                    </li>
                  ))}
                </ul>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#444' }}>Contras:</Typography>
                <ul style={{ paddingLeft: '16px' }}>
                  {puerto.cons.map((con, idx) => (
                    <li key={idx}>
                      <Typography variant="body2" color="text.secondary">{con}</Typography>
                    </li>
                  ))}
                </ul>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Puerto;