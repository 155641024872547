import React, { useState } from 'react';
import { Modal, Box, Typography, Button, Backdrop, Fade } from '@mui/material';
import { FaWhatsapp, FaMoneyBillWave } from 'react-icons/fa'; // Importar los iconos de WhatsApp y Dinero

const RegistroCitaModal = ({ open, onClose }) => {
  const [isCostInfoModalOpen, setIsCostInfoModalOpen] = useState(false);

  const handleFreeTutorial = () => {
    // Aquí puedes implementar el comportamiento del tutorial gratuito
    alert('El tutorial gratuito estará disponible pronto. Actualmente estamos en la fase beta.');
  };

  const handleOpenCostInfoModal = () => {
    setIsCostInfoModalOpen(true);
  };

  const handleCloseCostInfoModal = () => {
    setIsCostInfoModalOpen(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <Box sx={modalStyles}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#fff' }}>
              Asistencia Personalizada
            </Typography>
            <Typography variant="body1" sx={{ color: '#bbb', mb: 3 }}>
              ¿Necesita asistencia personalizada en vivo? Puede agendar una cita en línea con un costo que cubre hasta tres personas mayores 
              (los menores de 14 años no generan costo). Para grupos más grandes, el costo es:
            </Typography>
            <Typography variant="body1" sx={{ color: '#bbb', mb: 3 }}>
              - Hasta 7 personas mayores: $50.<br />
              - Hasta 10 personas mayores: $80.
            </Typography>
            <Typography variant="body1" sx={{ color: '#bbb', mb: 3 }}>
              Este servicio no garantiza un adelanto de su cita, solo asegura que el proceso esté completo y que no tenga problemas al ingresar el día de su cita.
            </Typography>
            <Typography variant="body1" sx={{ color: '#bbb', mb: 3 }}>
              También puede acceder a un tutorial gratuito. Actualmente, el tutorial está deshabilitado debido a que estamos en fase beta.
            </Typography>

            <Button
              component="a"
              href="https://wa.me/527202271108"
              target="_blank"
              rel="noopener noreferrer"
              startIcon={<FaWhatsapp />} // Añadir el ícono de WhatsApp
              sx={whatsappButtonStyles}
            >
              Contactar por WhatsApp
            </Button>

            <Button
              onClick={handleOpenCostInfoModal}
              startIcon={<FaMoneyBillWave />} // Añadir el ícono de dinero
              sx={moneyButtonStyles}
            >
              ¿Por qué cobran?
            </Button>

            <Button
              variant="contained"
              onClick={handleFreeTutorial}
              sx={{ ...buttonStyles, mt: 2 }}
              disabled // Deshabilitado mientras estamos en beta
            >
              Tutorial Gratuito (Próximamente)
            </Button>

            <Button
              onClick={onClose}
              sx={{ mt: 3, color: '#fff', border: '1px solid #fff', '&:hover': { bgcolor: '#555' } }}
            >
              Cerrar
            </Button>
          </Box>
        </Fade>
      </Modal>

      {/* Modal de Información sobre el Costo */}
      <Modal
        open={isCostInfoModalOpen}
        onClose={handleCloseCostInfoModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={isCostInfoModalOpen}>
          <Box sx={modalStyles}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#fff' }}>
              Información sobre el Costo
            </Typography>
            <Typography variant="body1" sx={{ color: '#bbb', mb: 3 }}>
              Aunque somos una fundación sin fines de lucro, tampoco somos una fundación con ánimo de pérdida.
              Utilizamos los recursos obtenidos a través del cobro de servicios para mantener nuestra infraestructura,
              cubrir los gastos operativos, y pagar a nuestros empleados, quienes trabajan arduamente para brindarle un 
              servicio de calidad.
            </Typography>
            <Button
              onClick={handleCloseCostInfoModal}
              sx={{ mt: 3, color: '#fff', border: '1px solid #fff', '&:hover': { bgcolor: '#555' } }}
            >
              Cerrar
            </Button>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

// Estilos del modal
const modalStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 400,
  bgcolor: 'rgba(0, 0, 0, 0.8)',
  borderRadius: 2,
  border: '1px solid rgba(255, 255, 255, 0.2)',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.7)',
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};

// Estilo para el botón de WhatsApp
const whatsappButtonStyles = {
  bgcolor: '#25D366',
  color: '#fff',
  '&:hover': {
    bgcolor: '#128C7E',
  },
  fontWeight: 'bold',
  width: '100%',
  mb: 2,
};

// Estilo para el botón de "¿Por qué cobran?"
const moneyButtonStyles = {
  bgcolor: '#ffb300',
  color: '#fff',
  '&:hover': {
    bgcolor: '#ff9800',
  },
  fontWeight: 'bold',
  width: '100%',
  mt: 2,
};

// Estilo para otros botones
const buttonStyles = {
  bgcolor: '#007bff',
  color: '#fff',
  '&:hover': {
    bgcolor: '#0056b3',
  },
  width: '100%',
};

export default RegistroCitaModal;
