import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';  // Ícono de información
import AssignmentIcon from '@mui/icons-material/Assignment';  // Ícono de registro
import ScheduleIcon from '@mui/icons-material/Schedule';  // Ícono de citas
import SecurityIcon from '@mui/icons-material/Security';  // Ícono de seguridad
import DevicesIcon from '@mui/icons-material/Devices';  // Ícono de tecnología
import FlightIcon from '@mui/icons-material/Flight';  // Ícono de avión
import WorkIcon from '@mui/icons-material/Work';  // Ícono de trabajo
import GavelIcon from '@mui/icons-material/Gavel';  // Ícono para asesoría para-legal
import HotelIcon from '@mui/icons-material/Hotel';  // Ícono de alojamiento

const ServicesCards = () => {
  const cardsData = [
    {
      title: 'Información integral y actualizada',
      description: 'Proporcionamos información precisa sobre procesos migratorios y aduaneros, manteniéndote informado de cambios y actualizaciones.',
      icon: <InfoIcon sx={{ fontSize: 50, color: '#3f51b5' }} />,
    },
    {
      title: 'Asesoría completa en registros',
      description: 'Brindamos orientación integral para completar registros correctamente, asegurando documentación adecuada.',
      icon: <AssignmentIcon sx={{ fontSize: 50, color: '#3f51b5' }} />,
    },
    {
      title: 'Optimización de la solicitud de citas',
      description: 'Aumentamos la probabilidad de obtener una cita rápida utilizando análisis de datos en tiempo real.',
      icon: <ScheduleIcon sx={{ fontSize: 50, color: '#3f51b5' }} />,
    },
    {
      title: 'Seguridad y Transparencia',
      description: 'Nos aseguramos de cumplir con estándares legales, reduciendo la vulnerabilidad ante malas prácticas y desinformación.',
      icon: <SecurityIcon sx={{ fontSize: 50, color: '#3f51b5' }} />,
    },
    {
      title: 'Innovación y Tecnología',
      description: 'Utilizamos algoritmos avanzados para optimizar la programación de citas y plataformas digitales accesibles para el usuario.',
      icon: <DevicesIcon sx={{ fontSize: 50, color: '#3f51b5' }} />,
    },
    {
      title: 'Asesoría en transporte aéreo',
      description: 'Ofrecemos asesoría en transporte, incluyendo vuelos, para facilitar tu desplazamiento seguro.',
      icon: <FlightIcon sx={{ fontSize: 50, color: '#3f51b5' }} />,
    },
    {
      title: 'Permisos de trabajo y SSN',
      description: 'Asesoramos en la solicitud de permisos de trabajo y SSN. En ocasiones logramos que se concedan de forma gratuita.',
      icon: <WorkIcon sx={{ fontSize: 50, color: '#3f51b5' }} />,
    },
    {
      title: 'Asesoría para-legal en trámites migratorios',
      description: 'Ofrecemos orientación para-legal en trámites migratorios dentro de EE. UU., garantizando asesoría confiable y segura.',
      icon: <GavelIcon sx={{ fontSize: 50, color: '#3f51b5' }} />,
    },
    {
      title: 'Asesoría en reservación de alojamientos seguros',
      description: 'Te ayudamos a encontrar y reservar alojamientos seguros para una estadía confortable durante tu proceso migratorio.',
      icon: <HotelIcon sx={{ fontSize: 50, color: '#3f51b5' }} />,
    },
  ];

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 4, mt: 4, mb: 6 }}>
      {cardsData.map((card, index) => (
        <Paper
          key={index}
          sx={{
            padding: 3,
            width: 300,
            textAlign: 'center',
            borderRadius: 4,
            boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.1)',
            border: '1px solid #ccc',
          }}
        >
          <Box sx={{ marginBottom: 2 }}>
            {card.icon}
          </Box>
          <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
            {card.title}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {card.description}
          </Typography>
        </Paper>
      ))}
    </Box>
  );
};

export default ServicesCards;
