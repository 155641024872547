import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { auth } from '../firebase'; // Importar autenticación de Firebase
import { useAuthState } from 'react-firebase-hooks/auth'; // Hook para manejar el estado de autenticación

const ProtectedRoute = () => {
  const [user, loading] = useAuthState(auth);

  if (loading) return null; // Mostrar nada mientras se carga la autenticación

  return user ? <Outlet /> : <Navigate to="/" />; // Redirigir a Home si no está autenticado
};

export default ProtectedRoute;
