import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Modal, Fade, Backdrop, FormControl, FormControlLabel, RadioGroup, Radio, Checkbox, FormGroup } from '@mui/material';

// Componente de Encuesta
const SurveyModal = ({ open, onClose }) => {
  const [answers, setAnswers] = useState({
    registroTiempo: '',
    mexicoRegistro: '',
    registroFamiliar: '',
    pagoAdelantado: '',
    citaEnTiempo: '',
    sugerenciasRedes: '',
    sugerenciasOpciones: [],
    companiaTelefono: '',
    wifiUsado: '',
    masDeUnRegistro: '',
    cantidadRegistros: '',
    masDeUnRegistroAlMismoTiempo: ''
  });

  // Manejar el cambio de respuestas
  const handleAnswerChange = (question, value) => {
    setAnswers((prev) => ({ ...prev, [question]: value }));
  };

  // Manejar el cambio de opciones con múltiples checkboxes
  const handleCheckboxChange = (option) => {
    setAnswers((prev) => {
      const currentOptions = prev.sugerenciasOpciones;
      if (currentOptions.includes(option)) {
        return {
          ...prev,
          sugerenciasOpciones: currentOptions.filter((item) => item !== option),
        };
      } else {
        return {
          ...prev,
          sugerenciasOpciones: [...currentOptions, option],
        };
      }
    });
  };

  // Manejar el envío de la encuesta (simulado)
  const handleSubmitSurvey = () => {
    // Simular envío de encuesta
    console.log('Respuestas de la encuesta:', answers); // Solo para pruebas
    // Aquí simplemente cerramos el modal como si se hubiera enviado la encuesta
    onClose(); // Llamar a la función onClose proporcionada por el componente padre
  };

  return (
    <Modal open={open} onClose={onClose} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
      <Fade in={open}>
        <Box sx={modalContainerStyles}>
          {/* Título de la encuesta */}
          <Typography variant="h6" sx={{ mb: 2,textAlign: 'center', fontWeight: 'bold' }}>Encuesta Anónima sobre el uso del Sistema CBPONE</Typography>

          {/* Disclaimer */}
          <Typography variant="body2" sx={{ mb: 2, textAlign: 'justify', color: '#ddd' }}>
            Los datos recogidos serán tratados de forma anónima. Pedimos que llenes completamente la encuesta, ya que esto 
            nos permitirá vislumbrar un amplio panorama del sistema CBPONE, para definir las mejores recomendaciones a seguir 
            y reconocer cuáles no son efectivas o son invenciones de personas interesadas en crear desinformación para su ganancia.
          </Typography>

          <Box sx={modalContentStyles}>
            {/* Pregunta 1: Desde cuándo tiene registro */}
            <FormControl component="fieldset" sx={{ width: '100%', mb: 2 }}>
              <Typography variant="body1">¿Desde cuándo tiene registro?</Typography>
              <RadioGroup
                value={answers.registroTiempo}
                onChange={(e) => handleAnswerChange('registroTiempo', e.target.value)}
              >
                <FormControlLabel value="no_tengo" control={<Radio />} label="No tengo aún" />
                <FormControlLabel value="menos_dos_meses" control={<Radio />} label="Hace menos de dos meses" />
                <FormControlLabel value="menos_seis_meses" control={<Radio />} label="Hace menos de seis meses" />
                <FormControlLabel value="mas_seis_meses" control={<Radio />} label="Más de seis meses" />
              </RadioGroup>
            </FormControl>

            {/* Pregunta 2: Registro en México */}
            <FormControl component="fieldset" sx={{ width: '100%', mb: 2 }}>
              <Typography variant="body1">¿Creó su registro en el territorio de México?</Typography>
              <RadioGroup
                value={answers.mexicoRegistro}
                onChange={(e) => handleAnswerChange('mexicoRegistro', e.target.value)}
              >
                <FormControlLabel value="si" control={<Radio />} label="Sí" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>

            {/* Pregunta 3: Registro familiar */}
            <FormControl component="fieldset" sx={{ width: '100%', mb: 2 }}>
              <Typography variant="body1">¿Su registro es de grupo familiar solamente?</Typography>
              <RadioGroup
                value={answers.registroFamiliar}
                onChange={(e) => handleAnswerChange('registroFamiliar', e.target.value)}
              >
                <FormControlLabel value="si" control={<Radio />} label="Sí" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>

            {/* Pregunta 4: Pago adelantado */}
            <FormControl component="fieldset" sx={{ width: '100%', mb: 2 }}>
              <Typography variant="body1">¿Ha pagado para que se le adelanten la cita?</Typography>
              <RadioGroup
                value={answers.pagoAdelantado}
                onChange={(e) => handleAnswerChange('pagoAdelantado', e.target.value)}
              >
                <FormControlLabel value="si" control={<Radio />} label="Sí" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>

            {/* Pregunta 5: Cita en tiempo */}
            {answers.pagoAdelantado === 'si' && (
              <FormControl component="fieldset" sx={{ width: '100%', mb: 2 }}>
                <Typography variant="body1">¿Le llegó la cita en el tiempo estipulado?</Typography>
                <RadioGroup
                  value={answers.citaEnTiempo}
                  onChange={(e) => handleAnswerChange('citaEnTiempo', e.target.value)}
                >
                  <FormControlLabel value="si" control={<Radio />} label="Sí" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            )}

            {/* Pregunta 6: Sugerencias de redes */}
            <FormControl component="fieldset" sx={{ width: '100%', mb: 2 }}>
              <Typography variant="body1">¿Ha seguido sugerencias para lograr la cita de redes sociales, conocidos, amistades?</Typography>
              <RadioGroup
                value={answers.sugerenciasRedes}
                onChange={(e) => handleAnswerChange('sugerenciasRedes', e.target.value)}
              >
                <FormControlLabel value="si" control={<Radio />} label="Sí" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>

            {/* Pregunta 7: Opciones seguidas si respondió sí */}
            {answers.sugerenciasRedes === 'si' && (
              <FormGroup sx={{ width: '100%', mb: 2 }}>
                <Typography variant="body1">Puede seleccionar varias de las opciones:</Typography>
                <FormControlLabel
                  control={<Checkbox checked={answers.sugerenciasOpciones.includes('horarios_puestos')} onChange={() => handleCheckboxChange('horarios_puestos')} />}
                  label="He seguido horarios/Puestos"
                />
                <FormControlLabel
                  control={<Checkbox checked={answers.sugerenciasOpciones.includes('borrar_cache')} onChange={() => handleCheckboxChange('borrar_cache')} />}
                  label="Borrar caché"
                />
                <FormControlLabel
                  control={<Checkbox checked={answers.sugerenciasOpciones.includes('cita_otro_idioma')} onChange={() => handleCheckboxChange('cita_otro_idioma')} />}
                  label="Solicitar la cita en otro idioma"
                />
                <FormControlLabel
                  control={<Checkbox checked={answers.sugerenciasOpciones.includes('telefono_especifico')} onChange={() => handleCheckboxChange('telefono_especifico')} />}
                  label="Usar una marca o modelo de teléfono específico"
                />
                <FormControlLabel
                  control={<Checkbox checked={answers.sugerenciasOpciones.includes('compania_telefono')} onChange={() => handleCheckboxChange('compania_telefono')} />}
                  label="Usar una compañía de teléfono específica o usar solo datos"
                />
                <FormControlLabel
                  control={<Checkbox checked={answers.sugerenciasOpciones.includes('mayusculas_nombre')} onChange={() => handleCheckboxChange('mayusculas_nombre')} />}
                  label="Escribir el nombre solo en mayúsculas o de alguna manera específica diferente a la manera ortográfica reglamentada"
                />
              </FormGroup>
            )}

            {/* Pregunta 8: Compañía de teléfono usada */}
            <TextField
              label="¿Cuál compañía de teléfono usa?"
              variant="outlined"
              fullWidth
              value={answers.companiaTelefono}
              onChange={(e) => handleAnswerChange('companiaTelefono', e.target.value)}
              sx={modalInputStyles}
            />

            {/* Pregunta 9: Uso de wifi */}
            <FormControl component="fieldset" sx={{ width: '100%', mb: 2 }}>
              <Typography variant="body1">¿Ha utilizado WiFi para solicitar su cita?</Typography>
              <RadioGroup
                value={answers.wifiUsado}
                onChange={(e) => handleAnswerChange('wifiUsado', e.target.value)}
              >
                <FormControlLabel value="si" control={<Radio />} label="Sí" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>

            {/* Pregunta 10: Creación de más de un registro */}
            <FormControl component="fieldset" sx={{ width: '100%', mb: 2 }}>
              <Typography variant="body1">¿Ha creado más de un registro?</Typography>
              <RadioGroup
                value={answers.masDeUnRegistro}
                onChange={(e) => handleAnswerChange('masDeUnRegistro', e.target.value)}
              >
                <FormControlLabel value="si" control={<Radio />} label="Sí" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>

            {/* Pregunta 11: Cuántos registros */}
            {answers.masDeUnRegistro === 'si' && (
              <TextField
                label="¿Cuántos registros?"
                variant="outlined"
                fullWidth
                type="number"
                value={answers.cantidadRegistros}
                onChange={(e) => handleAnswerChange('cantidadRegistros', e.target.value)}
                sx={modalInputStyles}
              />
            )}

            {/* Pregunta 12: Más de un registro al mismo tiempo */}
            <FormControl component="fieldset" sx={{ width: '100%', mb: 2 }}>
              <Typography variant="body1">¿Ha tenido más de un registro al mismo tiempo?</Typography>
              <RadioGroup
                value={answers.masDeUnRegistroAlMismoTiempo}
                onChange={(e) => handleAnswerChange('masDeUnRegistroAlMismoTiempo', e.target.value)}
              >
                <FormControlLabel value="si" control={<Radio />} label="Sí" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Box>

          {/* Botón de Enviar Encuesta */}
          <Button onClick={handleSubmitSurvey} variant="contained" fullWidth sx={modalButtonStyles}>
            Enviar Encuesta
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
};

// Estilos para el contenedor del modal y el contenido desplazable
const modalContainerStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 400,
  maxHeight: '80vh',
  bgcolor: 'rgba(0, 0, 0, 0.8)',
  borderRadius: 2,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: '#fff',
  overflow: 'hidden',
};

const modalContentStyles = {
  overflowY: 'auto',
  width: '100%',
  maxHeight: '70vh',
};

// Estilos para los inputs y botones del modal
const modalInputStyles = {
  mb: 2,
  bgcolor: '#444',
  borderRadius: 1,
  input: { color: '#fff' },
  label: { color: '#bbb' },
};

const modalButtonStyles = {
  mt: 2,
  bgcolor: '#fff',
  color: '#000',
  fontWeight: 'bold',
  '&:hover': {
    bgcolor: '#000',
    color: '#fff',
  },
};

export default SurveyModal;
